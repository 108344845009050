class Tooltips {

  /**
   * Note - bootstrap 5.0.0 beta 2 and popover.js v2 has a bug breaking animation
   * Must include data-bs-animation='false' on all tooltips bootstrap upgraded for fix
   * https://github.com/twbs/bootstrap/issues/32372
   */
  init() {
    $('[data-bs-toggle="tooltip"]').tooltip();
  }
}

export default new Tooltips();
