/* eslint-disable no-undef */
import 'slick-carousel';

class OverlayGallery {
  constructor() {
    this.sel = {
      overlayGalleryTrigger: '.overlayGalleryTrigger',
      galleryModal: '#overlayGalleryModal',
      photosGalleryItems: '.overlayGallery--photos .overlayGallery__item',
      activeTab: '#overlayGalleryModal .tab-pane.active',
      activeGallery: '#overlayGalleryModal .tab-pane.active .overlayGallery',
      tabNav: '#overlayGalleryModal .nav-tabs .nav-link',
      categoryTrigger: '#overlayGalleryModal .overlayGallery__category',
      galleryThumbnail: '#overlayGalleryModal .overlayGallery__thumbnail',
      galleryArrow: '#overlayGalleryModal .overlayGallery .slick-arrow',
      slickedGallery: '#overlayGalleryModal .overlayGallery.slick-initialized',
      zoominTrigger: '#overlayGalleryModal .icon__zoomin',
      zoomoutTrigger: '#lightboxModal  .icon__zoomout',
      lightboxCarousel: '#lightboxModal .lightbox__carousel',
      lightboxModal: '#lightboxModal',
      activeGalleryControls: '#overlayGalleryModal .tab-pane.active .overlayGallery__controls'
    };

    this.breakpoin__lg = '(min-width: 992px)';
    this.matchDesktop = window.matchMedia(this.breakpoin__lg).matches;
    this.type = 'video';
    this.category = 'all';
    this.currentSlider = null;
    this.lightboxCarousel = null;

    this.photoGalleryItems = $(this.sel.photosGalleryItems).clone();
    window.dataLayer = window.dataLayer || [];
  }

  bindEvents() {
    // check lightbox ratio
    $(this.sel.lightboxModal).toggleClass('landscape', $(window).width() / $(window).height() > 1.5);
    // check deeplink params
    this.checkUrlParams();

    // tigger the overlay gallery modal + initate the active gallery
    $(document).on('click', this.sel.overlayGalleryTrigger, (e) => {
      e.preventDefault();
      this.triggerGalleryModal();
      if (this.matchDesktop) {
        this.triggerGallery(this.sel.activeGallery);
      }
      this.triggerLaunchEvent();
    });

    // change tab and trigger the active gallery
    this.handleNavTab();

    // gallery sort the cagegory
    this.hanldeCategory();

    // Thumbnail click event to change the slider
    $(document).on('click', this.sel.galleryThumbnail, (e) => {
      e.preventDefault();
      this.gotoSlider(e.currentTarget);
    });

    // gallery arrow click event to sync the thumbnails
    $(document).on('click', this.sel.galleryArrow, () => {
      this.syncThumbnails();
    });

    // trigger lightbox
    this.handleLightbox();

    // add caption order
    this.addCaptionOrder(this.sel.activeGallery);
  }

  checkUrlParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const gallery = urlParams.get('gallery');

    if (gallery === 'yes') {
      this.type = urlParams.get('type') || 'video';
      this.triggerGalleryModal();
      setTimeout(() => {
        $(`[data-type="${this.type}"`)[0].click();
        this.triggerLaunchEvent();
      }, 500);
    }
  }

  syncThumbnails() {
    $(this.sel.activeTab).find('.current').removeClass('current');
    const currentIndex = this.currentSlider.slick('slickCurrentSlide');
    const currentThumbnail = $(this.sel.activeTab).find('.overlayGallery__thumbnail:not(.d-none)').eq(currentIndex).addClass('current');
    currentThumbnail[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

  triggerGallery(element) {
    if ($(element).hasClass('slick-initialized')) {
      this.currentSlider = $(element);
    } else {
      this.currentSlider = $(element).not('.slick-initialized').slick({
        adaptiveHeight: false,
        dots: false,
        infinite: false,
        slidesToShow: 1,
        prevArrow: $(this.sel.activeGalleryControls).find('.slick-prev'),
        nextArrow: $(this.sel.activeGalleryControls).find('.slick-next')
      });
      $(this.sel.activeGalleryControls).appendTo(element);
      $(this.sel.activeTab).find('.overlayGallery__category')[0].click();
      $(this.sel.activeTab).find('.overlayGallery__thumbnail').first().addClass('current');
      this.addCaptionOrder(element);

      this.currentSlider.off('afterChange');
      let previousIndex = null;
      this.currentSlider.on('afterChange', (event, slick, currentSlide) => {
        if (currentSlide !== previousIndex) {
          this.triggerAssetClickEvent(this.sel.activeGallery);
          previousIndex = currentSlide;
        }
      });
    }
  }

  gotoSlider(element) {
    $(element).parent().find('.current').removeClass('current');
    $(element).addClass('current');
    const index = $(element).parent().children(':not(.d-none)').index(element);
    this.currentSlider.slick('slickGoTo', index);
  }

  triggerGalleryModal() {
    let galleryModal = new bootstrap.Modal($(this.sel.galleryModal)[0]);
    galleryModal.show();
    $('body').addClass('gallery-open');

    $(this.sel.galleryModal).on('shown.bs.modal', () => {
      $('body').addClass('gallery-open');
    });

    $(this.sel.galleryModal).on('hidden.bs.modal', () => {
      $('body').removeClass('gallery-open');
    });
  }

  addCaptionOrder(gallery) {
    $(gallery).find('.overlayGallery__caption .order').remove();
    const total = $(gallery).find('.overlayGallery__item:not(.d-none)').length;
    $(gallery).find('.overlayGallery__item:not(.d-none)').each((i, item) => {
      if ($(item).find('.overlayGallery__caption').length > 0) {
        $(item).find('.overlayGallery__caption').prepend(`<span class="order">${i + 1} / ${total}</span>`);
      }
    });
  }

  filterSlidesDesktop() {
    $(this.sel.activeTab).find('.overlayGallery__item').removeClass('d-none');
    if (this.category === 'all') {
      $(this.sel.activeGallery).slick('slickUnfilter');
      $(this.sel.activeGallery).slick('slickGoTo', 0);
      $(this.sel.activeTab).find('.overlayGallery__thumbnail.d-none').removeClass('d-none');
    } else {
      $(this.sel.activeGallery).slick('slickUnfilter').slick('slickFilter', (index, element) => {
        return $(element).attr('data-sort-category') === this.category;
      });
      $(this.sel.activeGallery).slick('slickGoTo', 0);
      $(this.sel.activeTab).find('.overlayGallery__thumbnail').addClass('d-none');
      $(this.sel.activeTab).find(`.overlayGallery__thumbnail[data-sort-category="${this.category}"]`).removeClass('d-none');
    }
    $(this.sel.activeTab).find('.overlayGallery__thumbnail.current').removeClass('current');
    $(this.sel.activeTab).find('.overlayGallery__thumbnail:not(.d-none)').first().addClass('current');
  }

  filterSlidesMobile() {
    if (this.category === 'all') {
      $(this.sel.activeTab).find('.overlayGallery__item').removeClass('d-none');
    } else {
      $(this.sel.activeTab).find('.overlayGallery__item').addClass('d-none');
      $(this.sel.activeTab).find(`.overlayGallery__item[data-sort-category="${this.category}"]`).removeClass('d-none');
    }
  }

  checkMediaMatch() {
    this.matchDesktop = window.matchMedia(this.breakpoin__lg).matches;
    if (this.matchDesktop) {
      this.triggerGallery(this.sel.activeGallery);
      this.filterSlidesDesktop();
    } else {
      $(this.sel.slickedGallery).each((i, gallery) => {
        $(gallery).find('.overlayGallery__controls').appendTo($(gallery).parent());
        $(gallery).slick('slickUnfilter').slick('unslick');
      });

      this.filterSlidesMobile();
    }
  }

  handleNavTab() {
    $(document).on('click', this.sel.tabNav, (e) => {
      this.category = 'all';
      const id = $(e.currentTarget).attr('data-bs-target');
      if (this.matchDesktop) {
        this.type = $(e.currentTarget).attr('data-type');
        setTimeout(() => {
          this.triggerGallery(`${id} .overlayGallery`);
        }, 500);
      } else {
        $(this.sel.activeTab).find('.overlayGallery__category')[0].click();
        this.addCaptionOrder(`${id} .overlayGallery`);
      }
    });
    $(document).on('keydown', this.sel.tabNav, (e) => {
      if (e.key === 'Enter') {
        e.currentTarget.click();
      }
    });
  }

  hanldeCategory() {
    $(document).on('click', this.sel.categoryTrigger, (e) => {
      e.preventDefault();
      $(e.currentTarget).siblings('.active').removeClass('active');
      $(e.currentTarget).addClass('active');
      this.category = $(e.currentTarget).attr('data-category') || 'all';
      if (this.matchDesktop) {
        this.filterSlidesDesktop();
      } else {
        this.filterSlidesMobile();
      }
      this.addCaptionOrder(this.sel.activeGallery);
      this.triggerCategoryClickEvent();
    });
  }

  handleLightbox() {
    $(document).on('click', this.sel.zoominTrigger, (e) => {
      $(this.sel.lightboxModal).css('display', 'flex').hide().fadeIn();
      let currentIndex = 0;
      if (this.matchDesktop) {
        currentIndex = this.currentSlider.slick('slickCurrentSlide');
      } else {
        const currentItem = $(e.currentTarget).closest('.overlayGallery__item');
        currentIndex = currentItem.parent().children(':not(.d-none)').index(currentItem);
      }

      if (!this.lightboxCarousel) {
        $(this.sel.lightboxCarousel).html(this.photoGalleryItems);
        this.lightboxCarousel = $(this.sel.lightboxCarousel).not('.slick-initialized').slick({
          dots: false,
          infinite: false,
          slidesToShow: 1,
          fade: true,
          cssEase: 'linear'
        });
      }

      if (!!this.lightboxCarousel && this.category === 'all') {
        this.lightboxCarousel.slick('slickUnfilter');
      }

      if (!!this.lightboxCarousel && this.category !== 'all') {
        this.lightboxCarousel.slick('slickUnfilter').slick('slickFilter', (index, element) => {
          return $(element).attr('data-sort-category') === this.category;
        });
      }

      this.addCaptionOrder(this.sel.lightboxCarousel);
      this.lightboxCarousel.slick('slickGoTo', currentIndex);

      this.lightboxCarousel.off('afterChange');
      let previousIndex = null;
      this.lightboxCarousel.on('afterChange', (event, slick, currentSlide) => {
        if (currentSlide !== previousIndex) {
          this.triggerAssetClickEvent(this.sel.lightboxCarousel);
          previousIndex = currentSlide;
        }
      });
    });

    $(document).on('click', this.sel.zoomoutTrigger, () => {
      $(this.sel.lightboxModal).hide();
    });
  }

  triggerLaunchEvent() {
    window.dataLayer.push({
      event: 'gallery_launched',
      careHome: $('#overlayGalleryModal .carehome__title').text()
    });
  }

  triggerCategoryClickEvent() {
    window.dataLayer.push({
      event: 'gallery_asset_category_viewed',
      careHome: $('#overlayGalleryModal .carehome__title').text(),
      galleryParentCategory: this.type,
      assetCategoryName: this.category
    });
  }

  triggerAssetClickEvent(gallery) {
    window.dataLayer.push({
      event: 'gallery_asset_viewed',
      careHome: $('#overlayGalleryModal .carehome__title').text(),
      galleryparentCategory: this.type,
      assetcategoryName: this.category,
      galleryAssetName: $(gallery).find('.slick-current .overlayGallery__caption').attr('data-asset')
    });
  }

  init() {
    if (!$(this.sel.overlayGalleryTrigger).length) return;
    this.bindEvents();

    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        this.checkMediaMatch();
        // check lightbox ratio
        $(this.sel.lightboxModal).toggleClass('landscape', $(window).width() / $(window).height() > 1.5);
      }, 250);
    });
    // rotate the device to repoen the lightbox
    $(window).on('orientationchange', () => {
      if ($(this.sel.lightboxModal).is(':visible')) {
        const currentIndex = this.lightboxCarousel.slick('slickCurrentSlide');
        $(this.sel.lightboxModal).hide();
        setTimeout(() => {
          $(this.sel.zoominTrigger + ':visible').eq(currentIndex).trigger('click');
        }, 300);
      }
    });
  }
}

export default new OverlayGallery();
