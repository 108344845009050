class StandardContent {
  constructor() {
    this.sel = {
      html: 'html',
      outlinedPromoTitle: '.outlinedPromo__title',
      outlinedPromoContent: '.outlinedPromo__content',
      contentImg: '.standardContent picture img[class^="fr-"]'
    };

    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.setOutlineContent = this.setOutlineContent.bind(this);
  }

  bindEvents() {
    if (!!$(this.sel.outlinedPromoTitle)) {
      this.setOutlineContent();
      $(window).on('resize', () => {
        this.setOutlineContent();
      });
    }
    /* remove classes added by Froala editor */
    if (!!$(this.sel.contentImg)) {
      $(this.sel.contentImg).each((i, item) => $(item).removeClass());
    }
  }

  setOutlineContent() {
    let height = $(this.sel.outlinedPromoTitle).height();
    $(this.sel.outlinedPromoContent).css('margin-top', height);
  }

  init() {
    this.bindEvents();
  }
}

export default new StandardContent();
