class QuickReader {
  constructor() {
    this.sel = {
      quickReaderModal: '#quickReaderModal',
      quickReaderLoader: '.quickReader__loader',
      quickRead: '.quickread'
    };

    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.loadQuickReader = this.loadQuickReader.bind(this);
    this.constructContent = this.constructContent.bind(this);
  }

  bindEvents() {
    $(document).on('click', this.sel.quickRead, (e) => {
      e.preventDefault();
      this.loadQuickReader();
      this.constructContent(e.target);
    });
  }

  loadQuickReader() {
    let quickReaderModal = new bootstrap.Modal($(this.sel.quickReaderModal)[0]);
    quickReaderModal.show();
  }

  constructContent(element) {
    let quickReaderUrl = $(element).hasClass('quickread')
      ? $(element).attr('href')
      : $(element).closest('.quickread').attr('href');

    $(this.sel.quickReaderModal).find(this.sel.quickReaderLoader).removeClass('d-none v-hidden');

    $.ajax({
      url: quickReaderUrl,
      context: document.body
    }).done((data) => {
      $(this.sel.quickReaderModal).find(this.sel.quickReaderLoader).addClass('d-none v-hidden');
      let html = $(data).find('.standardContent')[0];
      let title =
        $(data).find('.standardContent h1').length > 0
          ? $(data).find('.standardContent h1')[0]
          : $(data).find('.standardContent h2')[0];
      $(this.sel.quickReaderModal).find('.modal-header h1').remove();
      $(this.sel.quickReaderModal).find('.modal-header h2').remove();
      $(this.sel.quickReaderModal).find('.modal-body').empty();
      $(this.sel.quickReaderModal).find('.modal-body').append(html);
      $(this.sel.quickReaderModal).find('.modal-header').append(title);
      $(data).find('.standardContent h1').length > 0
        ? $(this.sel.quickReaderModal).find('.standardContent h1').first().remove()
        : $(this.sel.quickReaderModal).find('.standardContent h2').first().remove();

      window.widgetModules.form.observeForm();
    });
  }

  init() {
    this.bindEvents();
  }
}

export default new QuickReader();
