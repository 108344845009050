class SocialMedia {
  constructor() {
    this.sel = {
      component: '.socialmediaPanel',
      copyLink: 'a[data-copy-link]'
    };

    this.init = this.init.bind(this);
    this.copyText = this.copyText.bind(this);
    this.fallback_copyText = this.fallbackCopyText(this);
  }
  init() {
    const $copyLink = $(this.sel.component).find(this.sel.copyLink);
    if ($copyLink) {
      $copyLink.on('click', (e)=>{
        const textToCopy = $copyLink.attr('href');
        this.copyText(textToCopy);
        const $existingCopiedTextMessage = $(this.sel.component).find('.copied-text');
        if ($existingCopiedTextMessage.length === 0) {
          $(this.sel.component).append($('<p>').addClass('mt-2 copied-text').text('The article link was copied.'));
        }
        e.preventDefault();
      });
    }
  }

  copyText(textToCopy) {
    if (!navigator.clipboard) {
    // use old commandExec() way
      this.fallbackCopyText(textToCopy);
    } else {
      navigator.clipboard.writeText(textToCopy)
        .catch(
          function () {
            console.log('There was an issue copying the link.'); // error
          });
    }
  }

  fallbackCopyText(textToCopy) {
    var input = document.createElement('input');
    input.setAttribute('value', textToCopy);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  }
}

export default new SocialMedia();
