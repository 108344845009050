class Form {
  constructor() {
    this.sel = {
      select: 'select',
      dataLayerForm: 'data-datalayer-form form',
      dataLayerFormContainer: '[data-datalayer-form]',
      dataLayerItem: '[data-include-in-data-layer]',
      formReplacementContainer: '.formwidget-submit-text'
    };
    this.dataLayerForms = {};
    this.formParentsBeingWatched = [];

    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.initSelects = this.initSelects.bind(this);
    this.addNativeEventTrigger = this.addNativeEventTrigger.bind(this);
    this.prepareDataLayer = this.prepareDataLayer.bind(this);
    this.observeForm = this.observeForm.bind(this);
    this.formMutationCallback = this.formMutationCallback.bind(this);
  }

  bindEvents() {
    this.initSelects();
    this.observeForm();
  }

  // https://stackoverflow.com/a/14570614/351426
  observeForm() {
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
    if (MutationObserver) {
      // have the observer observe for changes in children
      $(this.sel.dataLayerFormContainer).each((_, element) => {
        const parent = $(element).parent()[0];
        if (!this.formParentsBeingWatched.includes(parent)) {
          var mutationObserver = new MutationObserver(this.formMutationCallback);
          mutationObserver.observe($(element).parent()[0], { childList: true, subtree: true });
          this.formParentsBeingWatched.push(parent);
        }
      });
    }
  }

  formMutationCallback(mutationsList, _observer) {
    mutationsList.forEach((mutation) => {
      let isRemovedForm = false;
      let $removedForm;
      let isAddedReplacement = false;
      if (mutation.type === 'childList') {
        mutation.removedNodes.forEach((removedNode) => {
          if ($(removedNode).is(this.sel.dataLayerFormContainer)) {
            isRemovedForm = true;
            $removedForm = $(removedNode);
          }
        });
        mutation.addedNodes.forEach((addedNode) => {
          if ($(addedNode).is(this.sel.formReplacementContainer)) {
            isAddedReplacement = true;
          }
        });
      }
      if (isRemovedForm && isAddedReplacement) {
        const $dataLayerInputs = $removedForm.find(':input[data-include-in-data-layer]');
        const formName = $removedForm.attr('data-datalayer-form');
        this.prepareDataLayer($dataLayerInputs, formName);
        // _observer.disconnect();
      }
    });
  }

  prepareDataLayer($inputs, formName) {
    window.dataLayer = window.dataLayer || {};
    let formDataLayer = {};
    $inputs.each((_index, element) => {
      const inputName = $(element).attr('data-include-in-data-layer');
      if (inputName) {
        formDataLayer[inputName] = $(element).val();
      }
    });
    formDataLayer.event = 'formSubmit';
    formDataLayer.form = formName;

    window.dataLayer.push(formDataLayer);
  }

  select2CustomMatch(params, data) {
    if (!params.hasOwnProperty('term') || params.term.trim() === '') return data;
    if (typeof data.text === 'undefined') return null;
    if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) === 0) return $.extend({}, data, true);
    return null;
  }

  initSelects() {
    if (!!$(this.sel.select).length) {
      $(`${this.sel.select}:not([data-searchable])`).select2({
        minimumResultsForSearch: Infinity
      });
      $(`${this.sel.select}[data-searchable]`).select2({
        matcher: this.select2CustomMatch
      });

      $(`${this.sel.select}[data-searchable]`).on('select2:open', (e) => {
        $('.select2-search__field')[0].focus();
      });

      // 30/06/21 - JR - Adding this as select2 doesnt fire
      // native js change events, which sadly is what kentico is listening for
      // - Kentico's code uses ele.addEventListener("change", ...)
      // on kentico's form javascript for updating validation/visiblity.
      // see this issue on select2 github https://github.com/select2/select2/issues/1908
      $(this.sel.select).each((_, element) => {
        let options = {};
        if ($(element)[0].hasAttribute('data-searchable')) {
          options.matcher = this.select2CustomMatch
        } else {
          options.minimumResultsForSearch = Infinity;
        }
        if ($(element).attr('data-placeholder')) {
          options.placeholder = $(element).attr('data-placeholder');
          options.allowClear = false;
        }
        $(element).select2(options);
        $(element).one('change', this.addNativeEventTrigger);
      });
    }
  }

  addNativeEventTrigger(e) {
    if (window.document.documentMode) {
      // (IE doesnt support Event() constructor)
      // https://caniuse.com/?search=Event()
      var evt = document.createEvent('HTMLEvents');
      evt.initEvent('change', false, true);
      e.currentTarget.dispatchEvent(evt);
    } else {
      const event = new Event('change');
      e.currentTarget.dispatchEvent(event);
    }
    $(e.currentTarget).one('change', this.addNativeEventTrigger);
  }

  init() {
    this.bindEvents();
  }
}

export default new Form();


