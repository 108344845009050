import LocationMap from './LocationMap';
import Tooltips from './Tooltips';
import { convertLatLng } from './Google/GoogleMapHelpers';
import { getDistanceFromLatLon } from '../helpers';

class CareHomeList {
  constructor() {
    this.sel = {
      careHomeItemTemplate: '#careHomeList__template',
      careHomePreviewTemplate: '#careHomePreview__template',
      careHomeList: '.careHomeList',
      careHomeItem: '.careHomeItem',
      itemContent: '.careHomeItem__content',
      sortByElement: 'input[type="radio"]:checked',
      careHomeItemsWrapper: '.careHomeList__wrapper',
      searchForm: '.careHomeSearchForm',
      keywordsField: '.careHomeSearchForm [type="text"]',
      selectField: '.careHomeSearchForm select',
      select2Field: '.careHomeSearchForm .select2',
      searchSubmit: '.careHomeSearchForm .button__primary',
      componentLoadmore: '.careHomeList .loadmore .button',
      searchFormWrapper: '.searchForm__wrapper',
      errorInfo: '.searchForm .error-info',
      currentLocationData: 'data-current-location',
      readmore: '.careHomeList .readmore',
      currentLocationInput: 'input.currentLocation',
      searchForms: '.careHomeSearchForm, .vacanciesSearchForm',
      html: 'html',
      searchFormType: '.searchForm',
      component: '#bloodhound .typeahead',
      locationButton: '.autocomplete-location',
      search: '.careHomeSearchForm .button__primary',
      reset: '.careHomeSearchForm .button.button__secondary--hollow',
      currentLocationSelect: '.typeaheadCarehome',
      DropdownSelection: '.tt-suggestion.tt-selectable'
    };

    this.locationMap = new LocationMap();
    this.mobileLocationMap = new LocationMap(true);
    this.hasMap = false;
    this.start = 0;
    this.increaseBy = parseInt($(this.sel.careHomeList).attr('data-increaseby'), 10) || 10;
    this.listData = [];
    this.searchKeywords = '';
    this.type = '';
    this.lat = '';
    this.long = '';
    this.sortby = $(this.sel.sortByElement).length ? $(this.sel.sortByElement).val() : '';
    this.locations = [];
    this.currentLocation = '';

    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.populateItem = this.populateItem.bind(this);
    this.populateTemplate = this.populateTemplate.bind(this);
    this.convertDriveTime = this.convertDriveTime.bind(this);
    this.convertDistance = this.convertDistance.bind(this);
    this.setUpAutoComplete = this.setUpAutoComplete.bind(this);

    this.addReadmore = this.addReadmore.bind(this);
    this.toggleReadmore = this.toggleReadmore.bind(this);
  }

  bindEvents() {
    // get serach keywords, lat and lng value from query
    const query = window.location.href.split('?')[1] || '';
    query.split('&').forEach((item) => {
      if (item.split('=')[0] === 'search') {
        this.searchKeywords = decodeURI(item.split('=')[1]);
        $(this.sel.keywordsField).val(this.searchKeywords);
      }
      if (item.split('=')[0] === 'lat') {
        this.lat = decodeURI(item.split('=')[1]);
      }
      if (item.split('=')[0] === 'long') {
        this.long = decodeURI(item.split('=')[1]);
      }
    });

    if (window.location.href.split('?').length === 2 && !!$(this.sel.searchFormType).length) {
      $(this.sel.html).animate(
        {
          scrollTop: $(this.sel.searchForms).offset().top - 144 + 60
        },
        300,
      );
    }
    if (!!$(this.sel.careHomeList).length) {
      this.loadmoreItems = this.loadmoreItems.bind(this);
      this.formSubmission = this.formSubmission.bind(this);
      this.errorHandle = this.errorHandle.bind(this);

      this.getLocation = this.getLocation.bind(this);
      // get current location from data-current-location attribute set by backend
      this.currentLocation = convertLatLng($(this.sel.careHomeList).attr('data-current-location'));
      $(this.sel.selectField).select2({
        placeholder: 'Types of care'
      });

      this.locationMap.positionMap(this.sel.careHomeItemsWrapper);
      this.mobileLocationMap.positionMap(this.sel.careHomeItemsWrapper);
      // Sort the carehome json list by distance or drive time
      this.listData = window.careHomeList.careHomeItems;
      this.loadmoreItems();
      this.setUpAutoComplete();

      // toggle readmore content
      $(document).on('click', this.sel.readmore, (e) => {
        this.toggleReadmore(e);
      });

      // get value of selected in search input
      $(document).on('typeahead:selected', this.sel.component, (event, datum) => {
        this.searchKeywords = datum.text;
      });

      $(document).on('click', this.sel.componentLoadmore, (e) => {
        e.preventDefault();
        this.loadmoreItems();
      });

      $(document).on('keyup', this.sel.keywordsField, (e) => {
        this.searchKeywords = $(e.target).val().trim();
      });

      $(document).on('click', this.sel.searchSubmit, (e) => {
        e.preventDefault();
        this.formSubmission('carehome');
      });

      // search when dropdown item selected
      $(document).on('click', this.sel.DropdownSelection, () => {
        if (!!$(this.sel.searchForm).find('.error-info').length) {
          $(this.sel.errorInfo).remove();
        }
        this.formSubmission('carehome');
      });

      // search current location dropdown selection
      $(document).on('click', this.sel.locationButton, async (e) => {
        e.preventDefault();
        $('.typeahead').typeahead('val', 'Use my location');
        $('.tt-menu').removeClass('tt-open');
        $('.tt-menu').css('display', 'none');
        if (!!$(this.sel.errorInfo).length) {
          $(this.sel.errorInfo).remove();
        }
        await this.getLocation();
        this.formSubmission('carehome');
      });

      $(document).on('keypress', (e) => {
        if (e.key === 'Enter' || e.keyCode === '13') {
          this.formSubmission('carehome');
        }
      });

      this.errorHandle();
    }
    $(window).on('update.search.carehomelist.careuk', (e, bounds) => {
      this.handleDynamicMapsSearch(bounds);
    });
  }

  handleDynamicMapsSearch(bounds) {
    const center = bounds.getCenter().toJSON();
    let sortedData = [...this.listData];
    let homesWithinBounds = 0;
    let increaseBy = this.increaseBy;
    sortedData.forEach((home) => {
      const coord = {
        lat: parseFloat(home.location.split(',')[0].trim()),
        lng: parseFloat(home.location.split(',')[1].trim())
      };
      home.distance = getDistanceFromLatLon(coord, center).toString();
      home.driveTime = parseInt(home.distance * 2).toString();
      if (bounds.contains(coord)) homesWithinBounds++;
    });
    sortedData.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
    this.listData = sortedData;
    this.locations = [];
    this.start = 0;
    if (homesWithinBounds > this.increaseBy) this.increaseBy = homesWithinBounds;
    $(this.sel.careHomeItemsWrapper).html('');
    if (this.hasMap) {
      this.locationMap.clearMarkers();
      this.mobileLocationMap.clearMarkers();
    }
    this.loadmoreItems();
    this.increaseBy = increaseBy;
  }

  // Initalise typeahead.js library and configure
  setUpAutoComplete() {
    const endpoint = $(this.sel.component).attr('data-endpoint');
    // let queryString = '?query=%QUERY%';

    const results = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: endpoint + 'QUERY',
        wildcard: 'QUERY'
      },
      limit: Infinity
    });

    results.initialize();

    $(this.sel.component).typeahead(
      {
        highlight: true,
        hint: true,
        minLength: 2
      },
      {
        name: 'results',
        source: results.ttAdapter(),
        limit: Infinity,
        templates: {
          header:
            '<a href="#" class="autocomplete-location"><span class="icon-location-point"></span>Use my location</a>',
          suggestion: function (data) {
            let title = '';
            if (data.prefix) {
              title += data.prefix + ' ';
            }
            title += data.text;
            if (data.suffix) {
              title += ' ' + data.suffix;
            }
            if (data.type === 'carehome') {
              return (
                '<a class="typeaheadCarehome" href="' +
                data.url +
                '"><span class="icon-carehomeresult"></span>' +
                title +
                '</a>'
              );
            }
            return '<div><span class="icon-purple-map-pin"></span>' + title + '</div>';
          }
        },
        display: (item) => item.text
      },
    );
  }

  // get location of user
  async getGeolocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((pos) => {
        let lat = pos.coords.latitude;
        let lon = pos.coords.longitude;
        resolve({ lat, lon });
        reject((error) => {
          console.log(error);
        });
      });
    });
  }

  // pass in lat and long of user location
  async getLocation() {
    this.searchKeywords = 'Use my location';
    if ('geolocation' in window.navigator) {
      await this.getGeolocation()
        .then((pos) => {
          this.lat = pos.lat;
          this.long = pos.lon;
          this.searchKeywords = 'Use my location';
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  addReadmore() {
    $( this.sel.itemContent)
      .each((i, item) => {
        if (!$(item).hasClass('hasReadmore')) {
          $(item).addClass('hasReadmore');
        }
      });
  }

  toggleReadmore(e) {
    if ($(e.currentTarget).parent().hasClass('open')) {
      $(e.currentTarget).parent().removeClass('open');
      $(e.currentTarget).text('Read more');
    } else {
      $(e.currentTarget).parent().addClass('open');
      $(e.currentTarget).text('Read less');
    }
  }

  populateTemplate({ listData, start, end }) {
    listData.slice(start, end).map((item, i) => {
      let template = this.populateItem({ item, index: i + start });
      $(this.sel.careHomeItemsWrapper).append(template);
    });

    if (!this.sortby) {
      this.addReadmore();
    }
    Tooltips.init();
  }

  populateItem({ item, index, type }) {
    let templateHtml =
      type === 'careHome--preview'
        ? $(this.sel.careHomePreviewTemplate).html()
        : $(this.sel.careHomeItemTemplate).html();
    let template = $(templateHtml).clone();
    const {
      guid,
      isFeature,
      location,
      tag,
      title,
      town,
      distance,
      driveTime,
      image,
      imageAltText,
      name,
      rating,
      services,
      content,
      contacts,
      homeLink,
      county,
      tooltipShortlistAdd,
      tooltipShortlistRemove
    } = item;
    const latLng = convertLatLng(location);
    this.locations.push(latLng);
    $(template).attr('data-lat-lng', `${latLng.lat}${latLng.lng}`);
    $(template).attr('data-town', town);
    $(template)
      .find('.order')
      .html(parseInt(index + 1));
    isFeature ? $(template).addClass('feature') : '';
    !!tag
      ? $(template).find('.careHomeItem__tag').html(tag)
      : $(template).find('.careHomeItem__tag').remove();
    $(template).find('.careHomeItem__title').html(title);
    $(template).find('.careHomeItem__link').attr('href', homeLink);
    $(template).find('.careHomeItem__image img').attr('src', image);
    $(template).find('.careHomeItem__image img').attr('alt', imageAltText);
    $(template).find('.careHomeItem__name').html(name);
    $(template).find('.careHomeItem__rating').append(rating);
    $(template).find('.button').attr('href', homeLink);
    $(template).find('.button').attr('data-shortlist-name', name);
    $(template).find('.button').attr('data-shortlist-navigate', guid);
    $(template).find('[data-shortlist-toggle]').attr('data-shortlist-toggle', guid);
    $(template).find('[data-shortlist-name]').attr('data-shortlist-name', name);
    $(template).find('[data-shortlist-county]').attr('data-shortlist-county', county);
    $(template).find('[data-tooltip-add]').attr('data-tooltip-add', tooltipShortlistAdd);
    $(template).find('[data-tooltip-remove]').attr('data-tooltip-remove', tooltipShortlistRemove);
    $(template).find('[data-tooltip-add]').attr('title', tooltipShortlistAdd);

    let servicesHtml = $(template).find('.careHomeItem__services').html();
    $(template).find('.careHomeItem__services').empty();
    services.map(({ type, available }, i) => {
      $(template).find('.careHomeItem__services').append(servicesHtml);
      template.find('.careHomeItem__service').eq(i).append(type);
      if (!available) {
        template
          .find('.careHomeItem__service')
          .eq(i)
          .find('.icon-yes')
          .removeClass('icon-yes')
          .addClass('icon-no');
      }
    });

    if (contacts) {
      let contactsHtml = $(template).find('.careHomeItem__contactWrapper').html();
      $(template).find('.careHomeItem__contactWrapper').empty();
      contacts.map(({ type, number, responsetapcode }, i) => {
        $(template).find('.careHomeItem__contactWrapper').append(contactsHtml);
        $(template).find('.careHomeItem__contact').eq(i).append(number);
        $(template)
          .find('.careHomeItem__contact')
          .eq(i)
          .attr('class', 'careHomeItem__contact infinityNumber');
        $(template).find('.careHomeItem__contact').eq(i).attr('data-shortlist-phone', guid);
        $(template).find('.careHomeItem__contact').eq(i).attr('data-shortlist-name', name);
        $(template).find('.careHomeItem__contact').eq(i).attr('href', `tel:${number}`);
        $(template).find('.careHomeItem__contact').eq(i).html(number);
        $(template).find('.careHomeItem__contact').eq(i).find('.contact__title').html(type);
      });
    }

    if (!!this.sortby) {
      $(template)
        .find('.careHomeItem__distance')
        .html(
          this.sortby === 'byDistance'
            ? `(${this.convertDistance(distance)})`
            : `(${this.convertDriveTime(driveTime)})`,
        );
      $(template).find('.careHomeItem__content').remove();
    } else {
      $(template).find('.careHomeItem__content').prepend(content);
    }
    return template;
  }

  loadmoreItems(zoomChanged = false) {
    if (zoomChanged === false) {
      this.populateTemplate({
        listData: this.listData,
        start: this.start,
        end: this.start + this.increaseBy
      });
      this.start = this.start + this.increaseBy;
    }

    if (this.hasMap) {
      if (this.locations.length < this.listData.length) {
        let locationsToAdd = null;
        if (zoomChanged === true) {
          locationsToAdd = this.listData
            .slice(this.start)
            .map((home) => convertLatLng(home.location));
          this.locations = this.locations.concat(locationsToAdd);
        } else {
          locationsToAdd = this.locations.slice(
            Math.max(this.locations.length - this.increaseBy, 0),
          );
        }
        this.locationMap.addMarkers(locationsToAdd);
        this.mobileLocationMap.addMarkers(locationsToAdd);
      }
    } else {
      this.hasMap = true;
      this.locationMap.addMap({
        currentLoc: this.currentLocation,
        loadedLoc: this.locations
      });
      // Mobile map open in an overlay
      this.mobileLocationMap.triggerMapOverlay({
        currentLoc: this.currentLocation,
        loadedLoc: this.locations
      });
      this.mobileLocationMap.addModelTriger('.map__trigger--careHome');
      $('#map, #mapOverlay').on('zoomchanged.locationmap.careuk', () => {
        if (this.locations.length >= this.listData.length) return;
        this.loadmoreItems(true);
      });
    }
    const noMore =
      this.listData.length <= $(this.sel.careHomeItemsWrapper).find(this.sel.careHomeItem).length;
    this.locationMap.constructLoadmore(noMore);
    this.mobileLocationMap.constructLoadmore(noMore);
  }

  formSubmission(searchType) {
    this.type = $(this.sel.selectField).val();
    this.sortby = $(this.sel.sortByElement).val();

    if (!this.searchKeywords && this.searchKeywords === '' && !$(this.sel.errorInfo).length) {
      $(this.sel.keywordsField).parent().addClass('error');
      $(this.sel.searchFormWrapper).prepend(
        '<p class="error-info">Please fill in the search criteria.</p>',
      );
      return false;
    }

    if (
      this.searchKeywords === 'Use my location' &&
      this.lat === '' &&
      this.long === '' &&
      !$(this.sel.errorInfo).length
    ) {
      $(this.sel.keywordsField).parent().addClass('error');
      $(this.sel.searchFormWrapper).prepend(
        '<p class="error-info">Can\'t get your current location.</p>',
      );
      this.searchKeywords = '';
      $(this.sel.keywordsField).val('');
      $('.typeahead').typeahead('val', '');
      return false;
    }
    const baseUrl = location.protocol + '//' + location.host + location.pathname;

    if (!$(this.sel.errorInfo).length) {
      if (searchType === 'vacanciesList') {
        if (this.searchKeywords === 'Use my location') {
          window.location.href =
            `${baseUrl}?lat=${this.lat.toString()}&long=${this.long.toString()}&sortby=${
              this.sortby
            }` + (this.type ? `&jobtype=${this.type}` : '');
          return false;
        }
        window.location.href =
          `${baseUrl}?search=${this.searchKeywords}&sortby=${this.sortby}` +
          (this.type ? `&jobtype=${this.type}` : '');
        return false;
      }

      if (this.searchKeywords === 'Use my location') {
        window.location.href =
          `${baseUrl}?lat=${this.lat.toString()}&long=${this.long.toString()}&sortby=${
            this.sortby
          }` + (this.type ? `&type=${this.type}` : '');
        return false;
      }

      if (this.searchKeywords === 'Centre of map') {
        const currentMapCentre = this.locationMap.getCentre();
        window.location.href =
          `${baseUrl}?map=1&lat=${currentMapCentre.lat}&long=${currentMapCentre.lng}&sortby=${this.sortby}` +
          (this.type ? `&type=${this.type}` : '');
        return false;
      }

      window.location.href =
        `${baseUrl}?search=${this.searchKeywords}&sortby=${this.sortby}` +
        (this.type ? `&type=${this.type}` : '');
    }
  }

  errorHandle() {
    $(document).on('keyup', this.sel.keywordsField, (e) => {
      if (!!$(e.target).val() && !!$(e.target).parent().hasClass('error')) {
        $(e.target).parent().removeClass('error');
      }
      if (!$(this.sel.searchForm).find('.error').length) {
        $(this.sel.errorInfo).remove();
      }
    });

    $(this.sel.selectField).on('change', () => {
      if ($(this.sel.select2Field).hasClass('error')) {
        $(this.sel.select2Field).removeClass('error');
      }
      if (!$(this.sel.searchForm).find('.error').length) {
        $(this.sel.errorInfo).remove();
      }
    });
  }

  convertDriveTime(driveTime) {
    const mins = driveTime % 60 === 1 ? '1 min' : `${parseInt(driveTime % 60, 10)} mins`;
    if (driveTime > 60) {
      const hours =
        parseInt(driveTime / 60, 10) === 1 ? '1 hr' : `${parseInt(driveTime / 60, 10)} hrs`;
      return driveTime % 60 === 0 ? `${hours}` : `${hours} ${mins}`;
    }
    if (parseInt(driveTime, 10) === 60) {
      return '1 hr';
    }
    return mins;
  }

  convertDistance(distance) {
    const miles = distance > 1 ? 'miles' : 'mile';
    return `${distance} ${miles}`;
  }

  handlePageScroll() {
    if ($(window).width() > this.mobileBreakpoint && !$(this.sel.searchForm).length) {
      const div1Height = $(this.sel.careHomeList).outerHeight();
      const div1Bottom = $(this.sel.careHomeList).offset().top + div1Height;
      const div2Bottom =  $(window).scrollTop() + parseInt($(this.sel.mapWrapper).css('top'), 10) + $(this.sel.mapWrapper).outerHeight();
      const isUnfixed = div1Bottom <= div2Bottom;
      $(this.sel.careHomeIntroPanel).toggleClass('unfixed-position', isUnfixed);
    }
  }

  init() {
    if ($(this.sel.careHomeList).length <= 0) return;
    this.bindEvents();
    $(window).on('resize', () => {
      if (!!$(this.sel.careHomeItemsWrapper).length) {
        this.locationMap.positionMap(this.sel.careHomeItemsWrapper);
        this.mobileLocationMap.positionMap(this.sel.careHomeItemsWrapper);
      }
    });
  }
}

export default new CareHomeList();
