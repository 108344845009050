import { req, getIsMobile } from '../helpers';
import Tooltips from './Tooltips';


class Recent {
  constructor() {
    this.sel = {
      component: '.recent',
      toggle: '.recent__toggle',
      template: '#recent__itemTemplate',
      content: '.recent__content',
      items: '.carouselRecent__sliders',
      carouselRecent: '.carouselRecent__sliders',
    };

    this.bindEvents = this.bindEvents.bind(this);
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.ioCallback = this.ioCallback.bind(this);
    this.init = this.init.bind(this);

    this.io = new IntersectionObserver(this.ioCallback, {
      threshold: 0.1
    });
    this.slider = null;
  }

  bindEvents() {
    $(document).on('click', this.sel.toggle, this.handleToggleClick);
    $('body').click(e => {
      if (e.target.className === 'modal-backdrop show' || e.target.className === 'col-12' || e.target.className === 'recent') {
        $(this.sel.component).find(this.sel.content).addClass('d-none');
        $('.recent_close_btn').addClass('d-none');
        this.handleSticky(false);
        $('.recent__toggle').css('z-index', '1');
        $('body').css('padding-right', '');
      }
    });
    if (getIsMobile()) {
      $('.recent').css('display', 'none');
      this.handleScroll();
    }
  }

  handleSticky(status) {
    if (status === true) {
      $('body').modal('show');

    } else {
      $('body').modal('hide');
      $('body').css('display', '');
    }
  }

  handleScroll() {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        $('.recent').css('display', 'block');
      } else {
        $('.recent').css('display', 'none');
      }
      prevScrollpos = currentScrollPos;
    }
  }

  handleToggleClick(e) {
    e.preventDefault();
    $(this.sel.component).find(this.sel.content).toggleClass('d-none');
    if ($(this.sel.component).find(this.sel.content).hasClass('d-none')) {
      $('.recent_close_btn').addClass('d-none');
      this.handleSticky(false);
      $('.recent__toggle').css('z-index', '1');
      $('body').css('padding-right', '');
    } else {
      $(this.sel.carouselRecent).slick('refresh');
      $('.recent_close_btn').removeClass('d-none');
      $('.recent__toggle').css('z-index', '-1');
      this.handleSticky(true);
      $('body').css('padding-right', '0');
    }
  }

  ioCallback(entries, observer) {
    const templateHtml = $(this.sel.template).html();
    entries.forEach(entry => {
      if (entry.intersectionRatio <= 0) return;
      observer.unobserve(entry.target);
      const $items = $(entry.target).find(this.sel.items);
      $items.html('');
      let url = $(entry.target).attr('data-endpoint');
      url += `?guids=${this.getRecentGuids()}`;
      req(
        url
      ).then((homes) => {
        homes.forEach((home, index) => {
          let homeTemplate = templateHtml.replace(/%NAME%/g, home.name)
            .replace(/%LOCATION%/g, home.location)
            .replace(/%POSTCODE%/g, home.postcode)
            .replace(/%CQC%/g, home.cqc)
            .replace(/%IMG_ALT%/g, home.image.alt)
            .replace(/%IMG_PATH%/g, home.image.url)
            .replace(/%POSITION%/g, (index + 1))
            .replace(/%GUID%/g, home.guid)
            .replace(/%COUNTY%/g, home.county)
            .replace(/%TOOLTIPADD%/g, home.tooltipShortlistAdd)
            .replace(/%TOOLTIPREMOVE%/g, home.tooltipShortlistRemove)
            .replace(/%LINK%/g, home.url);
          $(entry.target).find(this.sel.items).append(homeTemplate);
        });
        this.carousel();
        Tooltips.init();
        // do something
      }).catch(() => {
        console.log('Request error on ioCallback ajax request');
      });
    });
  }

  carousel() {
    if (!!$(this.sel.carouselRecent).length) {
        $(this.sel.carouselRecent).not('.slick-initialized').slick({
          mobileFirst: true,
          draggable: false,
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          variableWidth: true,
          autoplay: false,
        });
    }
  }

  // Used for testing
  setCookie() {
    let expires = "";
    let date = new Date();
    let value = [];
    date.setTime(date.getTime() + (365*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
    value.push("12345");
    value.push("678910");
    value.push("test");
    document.cookie = `careuk_recents=${value}; path=/`;
  }

  getRecentGuids() {
    const cookie = `; ${document.cookie}`;
    const parts = cookie.split(`; careuk_recents=`);
    if (parts.length !== 2) return [];
    const currentGuids = parts.pop().split(';').shift();
    if (typeof currentGuids === 'undefined') return '';
    return currentGuids;
  }

  init() {
    const $component = $(this.sel.component);
    // this.setCookie();
    if ($component.length <= 0) return;
    // Hide recently viewed tab if no cookie / no recently viewed guids
    if (!this.getRecentGuids().length) {
      $component.hide();
      return;
    }
    this.bindEvents();
    this.io.observe(document.querySelector(this.sel.component));
  }
}

export default new Recent();
