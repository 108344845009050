/* eslint-disable consistent-return */
/* eslint-disable no-undef */
class NearestHome {
  constructor() {
    this.sel = {
      component: '.nearestHome',
      autoCompleteField: '#bloodhound .typeahead',
      searchForm: '.nearestHome .searchForm',
      searchFormWrapper: '.searchForm .form__wrapper',
      keywordsField: '.searchForm [name="search"]',
      selectField: '.searchForm select',
      currentLocation: '.autocomplete-location',
      latField: '.searchForm .latitube',
      lonField: '.searchForm .longitude',
      nearestHomeName: '.careHomeItem__name',
      nearestHomeDescription: '.careHomeItem__description',
      nearestHomeImage: '.nearestHome__image img',
      nearestHomeRating: '.careHomeItem__rating',
      nearestHomeServices: '.careHomeItem__services',
      nearestHomeUrl: '.careHomeItem__links .button__primary',
      errorInfo: '.searchForm .error-info'
    };

    this.handleIntersection = this.handleIntersection.bind(this);
  }

  bindEvents() {
    this.carehomeImageAlignment();
    $(this.sel.selectField).select2({
      placeholder: 'Types of care'
    });
    this.setUpAutoComplete();
    this.submitSearchForm();
    this.clearFormError();

    $(document).on('click', this.sel.currentLocation, async (e) => {
      e.stopPropagation();
      await this.getLocation();
    });

    $(document).on('typeahead:selected', this.sel.autoCompleteField, async (event, datum) => {
      if (datum.useMyLocation) {
        await this.getLocation();
      }
    });

    this.useMyLocationOnScroll();
  }

  // Handle intersection changes
  async handleIntersection(entries) {
    for (let entry of entries) {
      if (entry.isIntersecting) {
        await this.getLocation();
      }
    }
  }

  useMyLocationOnScroll() {
    const observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });
    observer.observe($(this.sel.component)[0]);
  }

  submitSearchForm() {
    const self = this;
    $(this.sel.searchForm).on('submit',  function handleFormSubmit(event) {
      event.preventDefault();

      if (!$(self.sel.keywordsField).val() && !$(self.sel.errorInfo).length) {
        $(self.sel.searchFormWrapper).before('<p class="error-info">Please fill in the search criteria.</p>');
        return false;
      }

      const formData = $(this).serializeArray().filter(field => field.value)
        .map(field => `${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`).join('&');

      $.ajax({
        type: $(this).attr('method'),
        url: $(this).attr('action'),
        data: formData,
        success: (response) => {
          $(self.sel.component).addClass('nearestHome--known');
          self.renderNearestHome(response.nearestHome[0]);
          self.carehomeImageAlignment();
        },
        error: (error) => {
          console.log(error);
        }
      });
    });
  }

  carehomeImageAlignment() {
    $(this.sel.component).each((i, item) => {
      const introHeight = $(item).find('.nearestHome__intro').outerHeight();
      if ($(item).find('.nearestHome__image').is(':visible')) {
        const carehomeImg = $(item).find('.nearestHome__image');
        $(window).width() > 991 ?  carehomeImg.css('margin-top', `${130 - introHeight}px`) : carehomeImg.css('margin-top', 0);
      }
    });
  }

  clearFormError() {
    $(document).on('keyup', this.sel.autoCompleteField, () => {
      if ($(this.sel.searchForm).find('.error-info').length > 0 && $(this.sel.keywordsField).val().length > 0) {
        $(this.sel.errorInfo).remove();
      }
    });
  }

  setUpAutoComplete() {
    const endpoint = $(this.sel.autoCompleteField).attr('data-endpoint');
    const results = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: endpoint + 'QUERY',
        wildcard: 'QUERY',
        transform: function transformedResults(response) {
          return [{
            useMyLocation: true,
            text: 'Use my location'
          },
          ...response];
        }
      },
      limit: Infinity
    });

    results.initialize();

    $(this.sel.autoCompleteField).typeahead(
      {
        highlight: true,
        hint: true,
        minLength: 2
      },
      {
        name: 'results',
        source: results.ttAdapter(),
        limit: Infinity,
        templates: {
          suggestion: (data) => {
            const { prefix = '', text, suffix = '', type, url, useMyLocation } = data;
            if (useMyLocation) {
              return `
                <div class="autocomplete-location"><span class="icon-location-point"></span>${text}</div>
              `;
            }

            const title = `${prefix ? `${prefix} ` : ''}${text}${suffix ? ` ${suffix}` : ''}`;
            if (type === 'carehome') {
              return `
                <a class="typeaheadCarehome" href="${url}">
                  <span class="icon-carehomeresult"></span>${title}
                </a>
              `;
            }
            return `
              <div>
                <span class="icon-purple-map-pin"></span>${title}
              </div>
            `;
          }
        },
        display: (item) => item.text
      },
    );
  }

  async getLocation() {
    if ('geolocation' in window.navigator) {
      try {
        const {lat, lon} = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                lat: position.coords.latitude,
                lon: position.coords.longitude
              });
            },
            (error) => reject(error)
          );
        });
        $(this.sel.latField).val(lat);
        $(this.sel.lonField).val(lon);
        $('.typeahead').typeahead('val', 'Use my location');
        $(this.sel.searchForm).submit();
      } catch (error) {
        $('.typeahead').typeahead('val', '');
        // eslint-disable-next-line no-console
        console.log(error);
        if (!$(this.sel.searchForm).find('.error-info').length) {
          $(this.sel.searchFormWrapper).before(`<p class="error-info">${error.message}<p>`);
        }
      }
    }
  }

  renderNearestHome(data) {
    const {
      name, description, rating, image, services, homeUrl
    } = data;
    const servicesHtml = services.map(({ service, availability}) =>
      `<li class="careHomeItem__service"><span class="${availability ? 'icon-yes' : 'icon-no'}"></span>${service}</li>`).join('');
    $(this.sel.nearestHomeName).text(name);
    $(this.sel.nearestHomeDescription).html(description);
    $(this.sel.nearestHomeRating).html(`<span class="icon-rating"></span>CQC: ${rating}`);
    $(this.sel.nearestHomeServices).html(servicesHtml);
    $(this.sel.nearestHomeImage).attr('src', image.url).attr('alt', image.altText);
    $(this.sel.nearestHomeUrl).attr('href', homeUrl);
  }

  init() {
    if (!$(this.sel.searchForm).length) return;
    this.bindEvents();
    $(window).on('resize', () => {
      this.carehomeImageAlignment();
    });
  }
}

export default new NearestHome();
