import { getIsMobile } from '../helpers';

class LiveChat {

  init() {
    if (typeof LiveChatWidget != 'undefined' && !getIsMobile()) {
      LiveChatWidget.on('ready', onReady);
    }

    function onReady(initialData) {
      // Chat Widget is ready
      $('#chat-widget-container').addClass('liveChat')
    }
  }
}

export default new LiveChat();
