import { Loader } from '@googlemaps/js-api-loader';

class Google {
  constructor() {
    this.key = false;
    this.loader = false;
  }

  get(key) {
    /**
     * Configure the googlemaps js-api-loader
     * https://www.npmjs.com/package/@googlemaps/js-api-loader
     */

    // Do nothing in dev environments or if key doesn't exist
    if (key === 'API-KEY' && this.loader === false) {
      return this.loader;
    } else if (!this.key) {
      this.key = key;
      this.loader = new Loader({
        apiKey: this.key,
        version: 'beta',
        libraries: ['marker']
      }).load();
      return this.loader;
    } else if (this.key !== false && key === this.key) {
      return this.loader;
    }
    return false;
  }
}

export default new Google();
