import Player from '@vimeo/player';
import { getIsMobile } from '../helpers';

class HeroPanel {
  constructor() {
    this.sel = {
      heroPanel: '.heroPanel',
      loopingText: '.loopingText',
      fallbackText: '.loopingText__fallback',
      heroBackgroundVideo: '.heroPanel--background-video',
      iframe: '.heroPanel__16-by-9-iframe-background-video',
      heroVideo: '#herovideo',
    };

    this.videoLength = 0;
    this.videos = [];
    this.videoInPlay = 0;

    this.init = this.init.bind(this);
  }

  init() {
    $(this.sel.heroPanel).find(this.sel.fallbackText).addClass('d-none v-hidden');
    $(this.sel.heroPanel).find(this.sel.loopingText).removeClass('d-none v-hidden');
    // eslint-disable-next-line new-cap
    $(this.sel.heroPanel).find(this.sel.loopingText).Morphext({
      animation: 'fadeIn',
      speed: 5000,
      separator: '|'
    });
    if ($('#herovideo-0').length > 0) {
      if ($(this.sel.heroPanel).data('show-on-mobile') !== 1 && getIsMobile()) return;
      this.initVideo();
    }
  }

  initVideo() {
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms))

    this.videos = $(this.sel.heroBackgroundVideo).data('videos');
    this.videoLength = this.videos.length;

    const loop = async () => {
      for (let i = 0; i < this.videoLength; i++) {
        this.createPlayer(i);
        await wait(750);
      }
    }
    loop();
  }

  createPlayer(index) {
    window[`hero-player-${index}`] = new Player(`herovideo-${index}`, this.getPlayerOptions(this.videos[index]));
    if (index === 0) {
      $(`${this.sel.heroVideo}-${index}`).css('visibility', 'unset');
    } else {
      $(`${this.sel.heroVideo}-${index}`).css('visibility', 'hidden');
    }

    window[`hero-player-${index}`].on('timeupdate', (current) => {
      if (this.videoInPlay === index) {
        if (current.duration <= (current.seconds + 0.4)) {
          this.playNextVideo(index);
        }
      }
    });
  }

  playNextVideo(index) {
    this.videoInPlay = this.getPosition(index + 1);
    window[`hero-player-${this.videoInPlay}`].setCurrentTime(0.01);
    for (let i = 0; i <= this.videoLength; i++) {
      if (i === this.videoInPlay) {
        $(`${this.sel.heroVideo}-${this.videoInPlay}`).css('visibility', 'unset');
      } else {
        $(`${this.sel.heroVideo}-${i}`).css('visibility', 'hidden');
      }
    }
  }

  getPosition(pos) {
    if (pos >= this.videoLength) {
      return 0;
    } else {
      return pos++;
    }
  }

  getPlayerOptions(video) {
    let url = `https://player.vimeo.com/video/${video.id}`;

    if (Object.prototype.hasOwnProperty.call(video, 'h')) {
      url = url.concat(`?h=${video.h}`);
    }

    return {
      url,
      muted: 1,
      autoplay: 0,
      loop: 1,
      autopause: 0,
      dnt: Object.prototype.hasOwnProperty.call(video, 'cookies') ? video.cookies : 1,
      quality: this.getQuality(video),
      controls: Object.prototype.hasOwnProperty.call(video, 'controls') ? video.controls : 0,
      background: Object.prototype.hasOwnProperty.call(video, 'background') ? video.background : 1
    }
  }

  getQuality(video) {
    if (this.getIsMobile()) {
      return '360p';
    } else if (video !== null && video !== undefined) {
      return Object.prototype.hasOwnProperty.call(video, 'quality') ? video.quality : 'auto';
    } else {
      return 'auto';
    }
  }

  getIsMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform));
  }
}

export default new HeroPanel();
