import Player from '@vimeo/player';

class VideoPanel {
  constructor() {
    this.sel = {
      videoPlayBtn: '.playBtn--video',
      ParamPlayBtn: '.playBtn--params',
      overlayVideoPlayBtn: '.playBtn--overlayVideo',
      overlayTourPlayBtn: '.playBtn--overlayTour',
      overlayGalleryCloseBtn: '.overlayGalleryModal .btn-close',
      overlayGalleryOtherTabBtns: '.overlayGalleryModal .nav-tabs #photos-tab, .overlayGalleryModal .overlayGallery__category',
      overlayGalleryThumbnail: '.overlayGallery__thumbnail',
      slickArrow: '.slick-arrow'
    };

    this.player = [];
    this.currentPlayerIndex = null;
  }

  bindEvents() {
    this.playVideo(this.sel.videoPlayBtn);
    this.playVideo(this.sel.overlayVideoPlayBtn);
    this.playParamVideo(this.sel.ParamPlayBtn);
    this.playParamVideo(this.sel.overlayTourPlayBtn);
    this.pauseVideo(this.sel.overlayGalleryCloseBtn);
    this.pauseVideo(this.sel.overlayGalleryOtherTabBtns);
    this.pauseVideo(this.sel.overlayGalleryThumbnail);
    this.pauseVideo(this.sel.slickArrow);
    this.pauseVideoOnSwipe();
  }

  playVideo(element) {
    $(document).on('click', element, (e) => {
      let $currentPlayBtn = $(e.target).closest('.playBtn');
      $currentPlayBtn.parent().addClass('hideOverlay');
      const $videoIframe = $currentPlayBtn.next();
      const url = $videoIframe.data('src');
      $videoIframe.attr('src', url);
      const currentPlayer = new Player($videoIframe);
      this.player = [...this.player, currentPlayer];
      currentPlayer.play();
      this.checkPlayerIndex();
    });
  }

  playParamVideo(element) {
    $(document).on('click', element, function () {
      $(this).parent().addClass('hideOverlay');
      const $paramsIframe = $(this).next();
      const url = $paramsIframe.data('src');
      $paramsIframe.attr('src', url);
    });
  }

  pauseVideo(element) {
    $(document).on('click', element, () => {
      if(this.currentPlayerIndex !== null) {
        this.player[this.currentPlayerIndex].pause();
      }
    });
  }

  pauseVideoOnSwipe(){
    $('.slick-initialized').on('swipe', () => {
      if(this.currentPlayerIndex !== null) {
        this.player[this.currentPlayerIndex].pause();
      }
    });
  }

  checkPlayerIndex() {
    this.player.forEach((player, index) => {
      player.on('play', () => {
        this.currentPlayerIndex = index;
      });
    });
  }

  init() {
    this.bindEvents();
  }
}

export default new VideoPanel();
