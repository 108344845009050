import LocationMap from './LocationMap';
import CareHomeList from './CareHomeList';
import SearchUtils from './SearchUtils';
import { convertLatLng } from './Google/GoogleMapHelpers';

class VacanciesList {
  constructor() {
    this.sel = {
      vacanciesList: '.vacanciesListAlt',
      vacanciesListResults: '.vacanciesListAlt__results',
      vacancyiesPerHome: '.vacanciesListAlt__perHome',
      searchForm: '.vacanciesSearchForm',
      keywordsField: '.vacanciesSearchForm .tt-input',
      selectField: '.vacanciesSearchForm select',
      select2Field: '.vacanciesSearchForm .select2',
      sortByElement: 'input[type="radio"]:checked',
      searchSubmit: '.vacanciesSearchForm [type="submit"]',
      componentLoadmore: '.vacanciesListAlt__more .button',
      searchFormWrapper: '.searchForm__wrapper',
      vacanciesTab: '.vacanciesSearchFormTab__link',
      searchOtherSubmit: '.searchFormOther [type="submit"]',
      selectFieldOther: '.searchFormOther select',
      itemContent: '.vacancyItem__content',
      readmore: '.vacanciesListAlt .readmore',
      component: '#bloodhound .typeahead',
      currentLocationInput: 'input.currentLocation',
      locationButton: '.autocomplete-location'
    };

    this.skip = window.vacanciesListConfig ? window.vacanciesListConfig.skip : 0;
    this.searchKeywords = '';
    this.jobtype = '';
    this.sortby = 'byDistance';
    this.jobsLocation = 'all';
    this.jobtypeOther = '';
    this.locationsToAdd = [];
    this.mobileLocationsToAdd = [];
    this.mobileLocationsCurrent = [];
    this.mobileHeadOfficeMapTriggered = false;

    this.locations = [];
    this.locationsObj = {};
    this.currentLocation = '';
    this.locationMap = new LocationMap();
    this.locationMapMobile = new LocationMap();
    this.locationMapOther = new LocationMap();
    this.locationMapOtherMobile = new LocationMap();

    this.loadmoreItems = 5;
    this.mobileBreakpoint = 992;

    this.populateTemplate = this.populateTemplate.bind(this);
    this.renderMobileMaps = this.renderMobileMaps.bind(this);
    this.prepareRenderMaps = this.prepareRenderMaps.bind(this);

    this.loadmoreType = { all: 'loadmore--all' };
    this.onHeadOfficeSearch = false;

    CareHomeList.addReadmore = CareHomeList.addReadmore.bind(this);
    CareHomeList.toggleReadmore = CareHomeList.toggleReadmore.bind(this);
    CareHomeList.convertDriveTime = CareHomeList.convertDriveTime.bind(this);
    CareHomeList.convertDistance = CareHomeList.convertDistance.bind(this);
  }

  bindEvents() {
    $('.vacanciesSearchFormTab select').select2({
      placeholder: 'Job Type'
    });
    this.getUrlParams();

    this.prepareRenderMaps();
    if ($(window).width() > this.mobileBreakpoint) {
      this.renderDesktopMaps();
    }

    this.setTabsLayout();
    this.setUpAutoComplete();
    CareHomeList.addReadmore();

    // toggle readmore content
    $(document).on('click', this.sel.readmore, (e) => {
      // start scroll action before toggle close
      if ($(e.target).parent().hasClass('open')) {
        $('html, body').animate(
          {
            // 27px is the padding above vacancy title
            scrollTop: $(e.target).parent().parent().offset().top - $('header').height() - 27
          },
          100,
        );
      }
      CareHomeList.toggleReadmore(e);
    });
    // get value of selected in search input
    $(document).on('typeahead:selected', this.sel.component, (event, datum) => {
      this.searchKeywords = datum.text;
      this.jobtype = $(this.sel.selectField).val();
      this.sortby = $(this.sel.sortByElement).val();
      event.preventDefault();
      // const searchUrl = this.constructSearchUrl();
      // window.location.href = searchUrl;
    });

    // loadmore functions
    $(document).on('click', this.sel.componentLoadmore, (e) => {
      e.preventDefault();
      const type = $(e.currentTarget).parent().attr('data-loadmore-type');
      if (type !== this.loadmoreType.all) {
        this.loadmorePerhome(e);
      } else {
        const url = this.constructURL({
          config: window.vacanciesListConfig
        });
        SearchUtils.loadmore(url, this.populateTemplate, type);
      }
    });

    // search form submission for all locations
    $(document).on('click', this.sel.searchSubmit, (e) => {
      e.preventDefault();
      const keywords = $(this.sel.keywordsField).val().trim();
      this.searchKeywords =
        keywords === 'Use my location' ? $(this.sel.currentLocationInput).val() : keywords;
      this.jobtype = $(this.sel.selectField).val();
      if (this.searchKeywords === '' && this.jobtype === '') return;
      this.jobtype = $(this.sel.selectField).val();
      this.sortby = $(this.sel.sortByElement).val();
      const searchUrl = this.constructSearchUrl();
      window.location.href = searchUrl;
    });

    // search form submission for head office
    $(document).on('click', this.sel.searchOtherSubmit, (e) => {
      e.preventDefault();
      this.jobtypeOther = $(this.sel.selectFieldOther).val();
      if (this.jobtypeOther === '') return;
      const searchUrl = this.constructSearchUrl();
      window.location.href = searchUrl;
    });

    // toggle tabs to switch layout
    $(this.sel.vacanciesTab).on('shown.bs.tab', (e) => {
      const currentJobLocation = $(e.target).attr('data-jobs-location');
      if (currentJobLocation === 'all' && this.onHeadOfficeSearch) {
        window.location.href = window.location.origin + window.location.pathname;
      }
      if (currentJobLocation !== this.jobsLocation) {
        this.jobsLocation = currentJobLocation;
        this.setTabsLayout();
      }
    });

    // handle use my location button
    $(document).on('click', this.sel.locationButton, async (e) => {
      e.preventDefault();
      $('.typeahead').typeahead('val', 'Use my location');
      $('.tt-menu').removeClass('tt-open');
      $('.tt-menu').css('display', 'none');
    });
  }

  getUrlParams() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (params.has('iscarehome') && params.get('iscarehome') === 'true') {
      this.jobsLocation = 'all';
      this.onHeadOfficeSearch = false;
    }

    if (params.has('iscarehome') && params.get('iscarehome') === 'false') {
      this.jobsLocation = 'head-office';
      this.onHeadOfficeSearch = true;
    }

    for (let [key, value] of params.entries()) {
      if (key === 'search') {
        if (value === $(this.sel.currentLocationInput).val()) {
          value = 'Use my location';
        }
        $('.vacanciesSearchForm [type="text"]').val(value);
        this.searchKeywords = value;
      }
      if (key === 'sortby') {
        if (value === 'byDistance') {
          $('#radioGoupDistance').prop('checked', true);
        }
        if (value === 'byDrivetime') {
          $('#radioGoupDrivetime').prop('checked', true);
        }
        this.sortby = value;
      }
      if (key === 'jobtype') {
        $(this.sel.selectField).val(value).trigger('change');
        this.jobtype = value;
      }
      if (key === 'jobtypeOther') {
        $(this.sel.selectFieldOther).val(value).trigger('change');
        this.jobtypeOther = value;
      }
    }
  }

  constructSearchUrl(paramsObj, url) {
    const urlObject = paramsObj || {
      iscarehome: this.jobsLocation === 'all' ? 'true' : 'false',
      jobtypeOther: this.jobtypeOther,
      search: this.searchKeywords,
      jobtype: this.jobtype,
      sortby: this.sortby
    };
    const baseUrl = url || location.protocol + '//' + location.host + location.pathname;
    const params = Object.keys(urlObject)
      .filter((key) => !!urlObject[key])
      .map((key) => `${key}=${urlObject[key]}`)
      .join('&');
    return `${baseUrl}?${params}`;
  }

  populateTemplate(data, type) {
    if (type === this.loadmoreType.all) {
      const { careHomes, hasMore } = data;
      careHomes.map((item) => {
        const itemHtml = this.populateHome(item);
        $('.vacanciesListAlt__list')
          .eq(this.jobsLocation === 'all' ? '0' : '1')
          .append(itemHtml);
      });
      this.skip = this.skip + careHomes.length;
      if ($(window).width() > this.mobileBreakpoint) {
        this.locationMap.addMarkers(this.locationsToAdd, 'vacanciesListAlt__perHome');
        this.locationsToAdd = [];
      }

      if (!hasMore) {
        $(`[data-loadmore-type="${this.loadmoreType.all}"]`).remove();
      }
    }
  }

  populateHome(item) {
    const {
      vacancyItems,
      carehomeId,
      careHomePinNumber,
      location,
      carehomeName,
      geoLocation,
      distance,
      driveTime,
      numberOfJobs,
      homeLink
    } = item;
    const DistanceFormat =
      this.sortby === 'byDistance'
        ? CareHomeList.convertDistance(distance)
        : CareHomeList.convertDriveTime(driveTime);
    const totalCountText = `${numberOfJobs} ${numberOfJobs > 1 ? 'jobs' : 'job'}`;
    const jobsHtml = vacancyItems
      .map((vacancyItem, index) => {
        return this.populateJob(vacancyItem, index);
      })
      .join('');

    const hasMoreHtml =
      numberOfJobs > this.loadmoreItems
        ? `<div class="vacanciesListAlt__more" data-current-skip="${this.loadmoreItems}"><button class="button button__secondary--hollow">Show more jobs</button></div>`
        : '';
    const careHomeHtml = `
        <li id="carehome-${carehomeId}" class="vacanciesListAlt__perHome" data-carehome-id="${carehomeId}" data-latlng="${geoLocation}">
          <div class="vacanciesListAlt__header">
            <div class="vacanciesListAlt__details">
              <div class="vacanciesListAlt__pin">
                <span class="icon-map-pin"></span>
                <span class="order">${careHomePinNumber}</span>
              </div>
              <div class="vacanciesListAlt__homename">
                <h3 id="carehome-01-title"><a href="${homeLink}" target="_blank">${carehomeName}</a></h3>
              </div>
              <div class="vacanciesListAlt__location">
                <p>${location} <span class="vacanciesListAlt__distance">(${DistanceFormat})</span></p>
              </div>
            </div>
            <div class="vacanciesListAlt__total">
              <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#carehome-${carehomeId}-results" aria-expanded="false" aria-controls="carehome-${carehomeId}-result">
                ${totalCountText}<span class="icon-add"></span>
              </button>
            </div>
          </div>
          <div id="carehome-${carehomeId}-results" class="vacanciesListAlt__info collapse" aria-labelledby="carehome-${carehomeId}-title" data-bs-parent="#carehome-${carehomeId}">
            <ul>${jobsHtml}</ul>
            ${hasMoreHtml}
          </div>
      </li>
    `;
    // add geoLocation to this.locations array
    if (!this.locationsObj.hasOwnProperty(carehomeId)) {
      this.locationsObj[carehomeId] = geoLocation;
      this.locationsToAdd.push(convertLatLng(geoLocation));
      this.mobileLocationsToAdd.push(convertLatLng(geoLocation));
    }
    return careHomeHtml;
  }

  populateJob(vacancyItem, index) {
    const { title, minMaxPay, shiftType, roleType, content, applyLink, jobDetailLink } =
      vacancyItem;
    const jobsHtml = `
      <li class="${index >= this.loadmoreItems ? 'd-none' : ''}">
        <div class="vacanciesListAlt__info-header">
          <h4 class="vacanciesListAlt__info-title"><a href="${applyLink}">${title}</a></h4>
          <ul>
            <li class="vacanciesListAlt__info-rate">${minMaxPay}</li>
            <li class="vacanciesListAlt__info-shift">${shiftType}</li>
            <li class="vacanciesListAlt__info-category">${roleType}</li>
          </ul>
        </div>
        <div class="vacancyItem__content wysiwyg hasReadmore">
          ${content}
          <<button type="button" aria-label="Read more" class="readmore">Read more</button>
        </div>
        <a href="${jobDetailLink.replace(
    /^~/,
    '',
  )}" class="button button__primary mt-2 mb-1">Find out more</a>
        <a href="${applyLink}" class="button button__primary me-4">Apply now</a>
      </li>
    `;
    return jobsHtml;
  }

  loadmorePerhome(e) {
    const currentSkip = parseInt($(e.currentTarget).parent().attr('data-current-skip'), 10);
    const nextSkip = currentSkip + this.loadmoreItems;

    const $jobItems = $(e.currentTarget).parent().prev().find('> li');
    if ($jobItems.length > nextSkip) {
      for (let i = currentSkip; i < nextSkip; i++) {
        $jobItems.eq(i).removeClass('d-none');
      }
      $(e.currentTarget).parent().attr('data-current-skip', nextSkip);
    } else {
      $jobItems.removeClass('d-none');
      $(e.currentTarget).parent().addClass('d-none');
    }
  }
  collectLocations() {
    $(this.sel.vacancyiesPerHome).each((i, item) => {
      let carehomeId = $(item).attr('data-carehome-id');
      let latLng = $(item).attr('data-latlng');
      if (!this.locationsObj.hasOwnProperty(carehomeId)) {
        this.locationsObj[carehomeId] = latLng;
        this.locations.push(convertLatLng(latLng));
      }
    });
  }

  constructURL({ config }) {
    const { url } = config;
    return this.constructSearchUrl(
      {
        search: this.searchKeywords,
        jobtype: this.jobtype,
        sortby: this.searchKeywords ? this.sortby : '',
        skip: this.skip,
        iscarehome: 'true'
      },
      url,
    );
  }

  // set tab layout depending on this.jobsLocation value
  setTabsLayout() {
    $('#map__main, #map__other, #mapOverlay, #mapOverlayOther').removeClass('d-none');
    $(this.sel.vacanciesListResults).removeClass('d-none');
    if (this.jobsLocation === 'all') {
      $(this.sel.vacanciesTab).eq(0).trigger('click');
      $(this.sel.vacanciesListResults).eq(1).addClass('d-none');
      $('#mapOverlayOther, #map__other').addClass('d-none');
    }
    if (this.jobsLocation === 'head-office') {
      $(this.sel.vacanciesTab).eq(1).trigger('click');
      $(this.sel.vacanciesListResults).eq(0).addClass('d-none');
      $('#map__main, #mapOverlay').addClass('d-none');
    }
  }

  prepareRenderMaps() {
    // get current location from data-current-location attribute set by backend
    let currentLocData = $('input.currentLocation').val();
    this.currentLocation = convertLatLng(currentLocData);
    this.collectLocations();
    // add mobile map trigger
    this.locationMapMobile.addModelTriger('.map__trigger--vacancies', this.renderMobileMaps);
  }

  renderMobileMaps() {
    // Mobile main map open in an overlay
    if (JSON.stringify(this.mobileLocationsCurrent) !== JSON.stringify([...this.locations, ...this.mobileLocationsToAdd])) {
      this.locationMapMobile.clearMarkers();
      this.mobileLocationsCurrent = [...this.locations, ...this.mobileLocationsToAdd];
      this.locationMapMobile.triggerMapOverlay({
        currentLoc: this.currentLocation,
        loadedLoc: this.mobileLocationsCurrent,
        mapId: 'mapOverlay'
      });
    }

    // Mobile head office map open in an overlay
    if (!this.mobileHeadOfficeMapTriggered) {
      let headOfficeLocation = $(this.sel.vacanciesListResults)
        .eq(1)
        .attr('data-head-office-location');

      if (!headOfficeLocation) {
        headOfficeLocation = '51.9242478, 0.9198703';
      }

      this.locationMapOtherMobile.triggerMapOverlay({
        currentLoc: convertLatLng(headOfficeLocation),
        loadedLoc: [convertLatLng(headOfficeLocation)],
        mapId: 'mapOverlayOther'
      });

      this.mobileHeadOfficeMapTriggered = true;
    }
  }

  // render the desktop maps
  renderDesktopMaps() {
    // main map
    this.locationMap.addMap({
      currentLoc: this.currentLocation,
      loadedLoc: this.locations,
      mapId: 'map__main',
      scrollItemClass: 'vacanciesListAlt__perHome'
    });

    // head office map
    let headOfficeLocation = $(this.sel.vacanciesListResults)
      .eq(1)
      .attr('data-head-office-location');

    if (!headOfficeLocation) {
      headOfficeLocation = '51.9242478, 0.9198703';
    }

    this.locationMapOther.addMap({
      currentLoc: convertLatLng(headOfficeLocation),
      loadedLoc: [convertLatLng(headOfficeLocation)],
      mapId: 'map__other'
    });

    // position desktop map left position
    this.locationMap.positionMap('.col-lg-7');
  }

  // Initalise typeahead.js library and configure
  setUpAutoComplete() {
    const endpoint = $(this.sel.component).attr('data-endpoint');

    const results = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: endpoint + 'QUERY',
        wildcard: 'QUERY'
      },
      limit: Infinity
    });

    results.initialize();

    $(this.sel.component).typeahead(
      {
        highlight: true,
        hint: true,
        minLength: 2
      },
      {
        name: 'results',
        source: results.ttAdapter(),
        limit: Infinity,
        templates: {
          header:
            '<a href="#" class="autocomplete-location"><span class="icon-location-point"></span>Use my location</a>',
          suggestion: function (data) {
            let title = '';
            if (data.prefix) {
              title += data.prefix + ' ';
            }
            title += data.text;
            if (data.suffix) {
              title += ' ' + data.suffix;
            }
            if (data.type === 'carehome') {
              return '<div><span class="icon-carehomeresult"></span>' + title + '</div>';
            }
            return '<div><span class="icon-purple-map-pin"></span>' + title + '</div>';
          }
        },
        display: (item) => item.text
      },
    );
  }

  init() {
    if (!$(this.sel.vacanciesList).length) return;
    this.bindEvents();
    $(window).on('resize', () => {
      if ($('.map__wrapper').length > 0) {
        this.locationMap.positionMap('.col-lg-7');
      }
    });
  }
}

export default new VacanciesList();
