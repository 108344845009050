import Google from './Google';
import {
  convertLatLng,
  defaultMapOptions,
  infoWindowContent,
  currentHomeButtonContent,
  nearbyHomesButtonContent,
  maximiseButtonContent,
  minimiseButtonContent,
  centreButtonContent,
  closeButtonContent,
  mapMarker,
  buttonTemplate,
  pink
} from './GoogleMapHelpers';

import { windowWidth, getIsMobile } from '../../helpers';

export class GoogleMap {
  constructor() {
    this.map = {};
    this.mapType = '';
    this.customControlButton = '';
    this.data = [];
    this.currentHome = {};
    this.noMoreHomes = false;
    this.nearbyHomesInstance = '';
    this.markers = [];
    this.selector = '';
    this.fullScreen = false;
    this.infoWindow = false;
    this.pinExists = false;
    this.loaded = false;
    this.autoZooming = false;
  }

  init(selector, data, currentHome, instance) {
    try {
      if ($(`#${selector}`).length <= 0) {
        return;
      }
      this.selector = selector;
      this.mapType = $(`#${this.selector}`).data('map-type');
      this.data = data;
      this.currentHome = currentHome;
      this.nearbyHomesInstance = instance;
      const key = this.getKey();
      this.loadMap(key);
    } catch (e) {
      console.log(e);
    }
  }

  zoomMap(zoomLevel) {
    this.autoZooming = true;
    this.map.setZoom(zoomLevel);
    this.autoZooming = false;
  }

  // Re-initialise the map when opened again. Fixes a bug where some buttons aren't drawn in correct place until map is touched
  reInit() {
    this.zoomMap(15);
  }

  getKey() {
    const key = $(`#${this.selector}`).data('google-api-key');
    if (!key) {
      throw 'No API key provided';
    } else {
      return key;
    }
  }

  // Used on init
  loadMap(key) {
    Google.get(key)
    .then((google) => {
      this.map = new google.maps.Map(document.getElementById(this.selector), this.getMapOptions());
      this.initInfoWindow();
      this.addCurrentHomeMarker(this.currentHome);
      this.addMarkerOtherHomes();
      this.addButtons();
      this.setMap();
      google.maps.event.trigger(this.markers[0], 'click');

      $('.nearbyHomesOnPageContainer').find('.slick-arrow').on('click', () => {
        const location = $('.nearbyHomesOnPageContainer').find('.slick-active').find('.pin').data('location');
        this.span(location);
      });

      $('.nearbyHomesOnPageContainer').find('.carouselNearbyHomes__sliders').on('swipe', () => {
        const location = $('.nearbyHomesOnPageContainer').find('.slick-active').find('.pin').data('location');
        this.span(location);
      });

      const handleMapZoomChanged = () => {
        if (this.noMoreHomes || this.autoZooming) return;
        $(window).trigger(`${this.nearbyHomesInstance}.nextpagedata.nearbyhomes.careuk`);
      };

      this.map.addListener('zoom_changed', handleMapZoomChanged);
      $(window).on(`${this.nearbyHomesInstance}.pageddata.nearbyhomes.careuk`, (e, pageData) => {
        const homes = pageData.data;
        if (homes.length > 0) {
          this.addMarkerOtherHomes(homes);
          if (this.mapType === 'nearby-homes') {
            this.markersApplyAll(this.map);
          }
        }
        this.noMoreHomes = !pageData.hasMore;
      });
    }).catch(e => {
      console.log('err', e);
      throw new Error(e);
    });
  }

  // Used on loadMap
  getMapOptions() {
    const options = defaultMapOptions();
    const { lat, lng } = convertLatLng(this.currentHome.location);
    options.center = { lat: lat, lng: lng };
    options.mapId = 'care_uk';
    options.zoomControl = true;
    options.zoomControlOptions = { position: google.maps.ControlPosition.RIGHT_CENTER };
    return options;
  }

  // Used on loadMap
  initInfoWindow() {
    this.map.addListener('click', function (event) {
      // If the event is a POI
      if (event.placeId) {
        // Call event.stop() on the event to prevent the default info window from showing.
        event.stop();
      }
    });

    this.infoWindow = new google.maps.InfoWindow({
      content: '',
      pixelOffset: new google.maps.Size(0,-25)
    });
  }

  // Used on loadMap
  addButtons() {
    if (this.mapType === 'current-home') {
      this.addTypeToggleButton();
      this.addCloseButton();
    }
    if (windowWidth() >= 768) {
      this.addFullscreenToggleButton();
      this.addCenterButton();
    }
  }

  // Used on loadMap
  setMap() {
    if (this.mapType !== 'current-home') {
      this.shrinkView();
      this.showMarkers();
    }
    this.spanTo();
    this.spanToCurrentEvent();
  }

  addCurrentHomeMarker(home) {
    const { lat, lng } = convertLatLng(home.location);
    const latLng = new google.maps.LatLng(lat, lng);

    const marker = new google.maps.marker.AdvancedMarkerView({
      position: latLng,
      map: this.map,
      content: mapMarker('primary', home.pin.toString(), 'd-none', home.name)
    });

    google.maps.event.addListener(marker, 'click', () => {
      this.resetPinState();
      marker.content.children[1].classList.remove('d-none');
      this.showInfoWindow(home, marker, true);
    });

    this.markers.push(marker);
  }

  addMarkerOtherHomes(homes = null) {
    if (homes === null) homes = this.data;
    homes.forEach(home => {
      const { lat, lng } = convertLatLng(home.location);

      const marker = new google.maps.marker.AdvancedMarkerView({
        position: new google.maps.LatLng(lat, lng),
        map: null,
        content: mapMarker('secondary', home.pin.toString(), 'd-none', home.name)
      });

      google.maps.event.addListener(marker, 'click', () => {
        this.resetPinState();
        marker.content.children[1].classList.remove('d-none');
        this.showInfoWindow(home, marker);
        this.scrollToView(home.pin.toString());
      });

      this.markers.push(marker);
    });
  }

  stopPulsing() {
    this.markers.forEach(marker => {
      marker.content.children[1].classList.add('d-none');
    });
  }

  showInfoWindow(home, marker, primary = false) {
    this.infoWindow.close();
    this.infoWindow.setContent(infoWindowContent(home, primary));
    this.infoWindow.open({
      anchor: marker,
      map: this.map
    });
  }

  markersApplyAll(map) {
    for (let i = 0; i < this.markers.length; i++) {
      if (i !== 0) {
        this.markers[i].map = map;
      }
    }
  }

  // Reset back to current home view
  hideMarkers() {
    this.resetPinState();
    this.markersApplyAll(null);
    this.spanToCurrent();
    this.zoomMap(15);
    this.showInfoWindow(this.currentHome, this.markers[0], true);
  }

  // Change to all homes view
  showMarkers() {
    this.resetPinState();
    this.markersApplyAll(this.map);
    this.zoomMap(11);
    this.showInfoWindow(this.currentHome, this.markers[0]);
  }

  scrollToView(id) {
    if (!getIsMobile()) {
      $(`#${this.selector}`).closest('.nearbyHomesMap').find('.list').find(`div[data-pin=${id}]`)[0].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    $(`#${this.selector}`).closest('.nearbyHomesMap').find('.list').find(`div[data-pin=${id}]`)[0].style.border = '2px solid #560a61';
    $('.carouselNearbyHomes__sliders').slick('slickGoTo', id - 2);
  }

  resetPinState() {
    if (this.pinExists === true) {
      this.stopPulsing();
      if (!getIsMobile()) {
        if (this.loaded) {
          $(`#${this.selector}`).closest('.nearbyHomesMap').find('.list').find('h4')[0].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        } else {
          this.loaded = true;
        }
      }
      $(`#${this.selector}`).closest('.nearbyHomesMap').find('.list').find('.nearbyHomesLocationCard').css('border', '2px solid #ffffff');
    } else {
      this.pinExists = true;
    }
  }

  addCloseButton() {
    const div = document.createElement('div');
    const btn = buttonTemplate();
    div.setAttribute('data-bs-dismiss', 'modal');
    div.addEventListener('click', () => {
      this.switchFullScreen();
      this.resetPinState();
    });
    btn.innerHTML = closeButtonContent();
    btn.setAttribute('id', `${this.selector}-closeButton`);
    btn.style.display = 'none';
    div.appendChild(btn);
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(div);
  }

  addFullscreenToggleButton() {
    const div = document.createElement('div');
    const btn = buttonTemplate();
    btn.innerHTML = maximiseButtonContent();
    btn.setAttribute('id', `${this.selector}-maximiseControlButton`);
    btn.addEventListener('click', () => {
      this.switchFullScreen();
    });
    div.appendChild(btn);
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(div);
  }

  addTypeToggleButton() {
    const div = document.createElement('div');
    const btn = buttonTemplate();
    btn.style.border = `2px solid ${pink}`;
    btn.style.color = `${pink}`;
    btn.innerHTML = nearbyHomesButtonContent();
    btn.setAttribute('id', `${this.selector}-customControlButton`);
    btn.addEventListener('click', () => {
      this.switchMapType();
    });
    if (getIsMobile() === true) {
      btn.style.marginBottom = '94px';
    }

    div.appendChild(btn);
    this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(div);
  }

  addCenterButton() {
    const div = document.createElement('div');
    const btn = buttonTemplate();
    btn.innerHTML = centreButtonContent();
    btn.setAttribute('id', `${this.selector}-centerToggleButton`);
    btn.addEventListener('click', () => {
      this.spanToCurrent();
    });
    div.appendChild(btn);
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(div);
  }

  switchMapType() {
    switch (this.mapType) {
      case 'current-home':
        this.shrinkView();
        document.getElementById(`${this.selector}-customControlButton`).innerHTML = currentHomeButtonContent();
        document.getElementById(`${this.selector}-customControlButton`).style.margin = '8px 12px 14px 0px';
        this.showMarkers();
        google.maps.event.trigger(this.markers[0], 'click');
        break;

      case 'nearby-homes':
        this.expandView();
        document.getElementById(`${this.selector}-customControlButton`).innerHTML = nearbyHomesButtonContent();
        document.getElementById(`${this.selector}-customControlButton`).style.margin = '8px 12px 94px 0px';
        this.hideMarkers();
        google.maps.event.trigger(this.markers[0], 'click');
        break;

      default:
        break;
    }
  }

  shrinkView() {
    const element = $(`#${this.selector}`);
    element.closest('.nearbyHomesMap').find('.listContainer').removeClass('d-none');
    element.closest('.nearbyHomesMap').find('.mobileCarousel').removeClass('d-none');
    element.closest('.nearbyHomesMap').find('.googleMap').addClass('googleMapAddCarousel');
    element.closest('.nearbyHomesMap').find('.carouselNearbyHomes__sliders').slick('refresh');
    $('.nearbyHomesModal').find('.slick-arrow').on('click', () => {
      const location = $('.nearbyHomesModal').find('.slick-active').find('.pin').data('location');
      this.span(location);
    });
    $('.nearbyHomesModal').find('.carouselNearbyHomes__sliders').on('swipe', () => {
      const location = $('.nearbyHomesModal').find('.slick-active').find('.pin').data('location');
      this.span(location);
    });
    this.mapType = 'nearby-homes';
  }

  expandView() {
    const element = $(`#${this.selector}`);
    element.closest('.nearbyHomesMap').find('.listContainer').addClass('d-none');
    element.closest('.nearbyHomesMap').find('.mobileCarousel').addClass('d-none');
    element.closest('.nearbyHomesMap').find('.googleMap').removeClass('googleMapAddCarousel');
    this.mapType = 'current-home';
  }

  switchFullScreen() {
    const element = $(`#${this.selector}`);
    if (!this.fullScreen) {
      // inline
      element.closest('.nearbyHomesMap').parent().css('position', 'fixed');
      element.closest('.nearbyHomesMap').parent().css('top', '0');
      element.closest('.nearbyHomesMap').parent().css('width', '100%');
      element.closest('.nearbyHomesMap').parent().css('height', '100%');
      element.closest('.nearbyHomesMap').parent().css('z-index', '1000');
      element.closest('.nearbyHomesMap').parent().css('background-color', 'white');
      element.closest('.nearbyHomesMap').find('.googleMap').css('height', '100vh');
      element.closest('.nearbyHomesMap').find('.googleMap').css('position', 'relative');
      element.closest('.nearbyHomesMap').find('.googleMap').css('width', 'auto');
      element.closest('.nearbyHomesMap').find('.list').css('height', 'calc(100vh - 250px)');
      document.getElementById('nearbyHomesOnPage').classList.remove('container');
      document.getElementById('nearbyHomesOnPage').style.paddingLeft = '12px';
      $('body').css('overflow', 'hidden');

      // modal
      element.closest('.nearbyHomesModal').find('.modal-dialog').css('margin', '0');

      // button adjust
      document.getElementById(`${this.selector}-maximiseControlButton`).innerHTML = minimiseButtonContent();
      document.getElementById(`${this.selector}-maximiseControlButton`).style.border = `2px solid ${pink}`;
      document.getElementById(`${this.selector}-maximiseControlButton`).style.color = `${pink}`;

      if (document.getElementById(`${this.selector}-closeButton`)) {
        document.getElementById(`${this.selector}-closeButton`).style.display = 'block';
      }

      this.fullScreen = true;
    } else {
      element.closest('.nearbyHomesMap').parent().css('position', '');
      element.closest('.nearbyHomesMap').parent().css('top', '');
      element.closest('.nearbyHomesMap').parent().css('width', '');
      element.closest('.nearbyHomesMap').parent().css('height', '');
      element.closest('.nearbyHomesMap').parent().css('z-index', '');
      element.closest('.nearbyHomesMap').parent().css('background-color', '');
      element.closest('.nearbyHomesMap').find('.googleMap').css('height', '');
      element.closest('.nearbyHomesMap').find('.list').css('height', '');
      $('body').css('overflow', '');
      document.getElementById('nearbyHomesOnPage').classList.add('container');
      element.closest('.nearbyHomesMap').find('.googleMap').css('position', '');
      element.closest('.nearbyHomesMap').find('.googleMap').css('width', '');
      document.getElementById('nearbyHomesOnPage').style.paddingLeft = '';

      // modal
      element.closest('.nearbyHomesModal').find('.modal-dialog').css('margin', '');

      // button adjust
      document.getElementById(`${this.selector}-maximiseControlButton`).innerHTML = maximiseButtonContent();
      document.getElementById(`${this.selector}-maximiseControlButton`).style.border = '2px solid #C8C8C8';
      document.getElementById(`${this.selector}-maximiseControlButton`).style.color = '#000000';

      if (document.getElementById(`${this.selector}-closeButton`)) {
        document.getElementById(`${this.selector}-closeButton`).style.display = 'none';
      }

      this.fullScreen = false;
    }
  }

  resetMap() {
    this.expandView();
    document.getElementById(`${this.selector}-customControlButton`).textContent = 'NEARBY HOMES';
    this.hideMarkers();
    this.zoomMap(16);
  }

  spanTo() {
    $('.googleMapSpanToLocation').on('click', (e) => {
      e.stopPropagation();
      e.stopImmediatePropagation();
      const location = $(e.currentTarget).data('location');
      this.span(location);
    });
  }

  span(location) {
    const {lat, lng} = convertLatLng(location);
    const myLatlng = new google.maps.LatLng(lat + 0.002, lng);
    if (this.markers.some(marker => {
      if (marker.position.lat === lat && marker.position.lng === lng) {
        google.maps.event.trigger(marker, 'click');
      }
    }) !== true) {
      this.map.panTo(myLatlng);
    } else {
      google.maps.event.trigger(this.markers[0], 'click');
    }
  }

  spanToCurrentEvent() {
    $('.googleMapSpanToCurrent').on('click', (e) => {
      const location = $(e.currentTarget).data('location');
      this.span(location);
    });
  }

  spanToCurrent() {
    const { lat, lng } = convertLatLng(this.currentHome.location);
    const myLatlng = new google.maps.LatLng(lat + 0.002, lng);
    google.maps.event.trigger(this.markers[0], 'click');
    this.map.panTo(myLatlng);
  }
}
