class FeaturePanel {
  constructor() {
    this.sel = {
      featurePanel: '.featurePanel',
      featurePanelRow: '.featurePanel__row',
      featurePanelPortraitRow: '.featurePanel__portrait'
    };

    this.mobileBreakpoint = 992;
    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.mobileLayout = this.mobileLayout.bind(this);
  }

  bindEvents() {
    this.mobileLayout();
  }

  mobileLayout() {
    if ($(document).width() < this.mobileBreakpoint) {
      $(this.sel.featurePanelPortraitRow).each((index) => {
        if ($(this.sel.featurePanelPortraitRow).eq(index).find('.featurePanel__content').index() === 0) {
          $(this.sel.featurePanelPortraitRow).eq(index).addClass('revertRow');
        }
      });
      return true;
    }
    $(this.sel.featurePanel).find('.revertRow').removeClass('revertRow');
  }

  init() {
    this.bindEvents();
    $(window).on('resize', () => this.mobileLayout());
  }
}

export default new FeaturePanel();
