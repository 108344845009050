class ContactPanel {
  constructor() {
    this.sel = {
      sendMessage: '.contactPanel .sendMessage',
      backBtn: '.contactPanel .btn-back',
      panelOne: '.contactPanel--one',
      panelTwo: '.contactPanel--two'
    };
    this.hiddenClasses = ['v-hidden', 'd-none'];

    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.gotoPanelOne = this.gotoPanelOne.bind(this);
    this.gotoPanelTwo = this.gotoPanelTwo.bind(this);
  }

  bindEvents() {
    $(document).on('click', this.sel.sendMessage, (e) => {
      e.preventDefault();
      this.gotoPanelTwo();
    });
    $(document).on('click', this.sel.backBtn, () => {
      this.gotoPanelOne();
    });
  }

  gotoPanelOne() {
    $(this.sel.panelOne).removeClass(this.hiddenClasses);
    $(this.sel.panelTwo).addClass(this.hiddenClasses);
  }

  gotoPanelTwo() {
    $(this.sel.panelOne).addClass(this.hiddenClasses);
    $(this.sel.panelTwo).removeClass(this.hiddenClasses);
  }

  init() {
    this.bindEvents();
  }
}

export default new ContactPanel();
