// eslint-disable-next-line import/prefer-default-export
export function req(endpoint) {
  return $.ajax({
    url: endpoint,
    type: 'get',
    dataType: 'json',
    success: (data) => data,
    error: (e) => {
      console.log('Request error', e); // eslint-disable-line no-console
    }
  });
}

export function randomString(length, chars) {
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
}

export function getIsMobile() {
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
  );
}

export function windowWidth() {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

export function environment() {
  return process.env.NODE_ENV || 'development';
}

export class CookieConsent {
  static onConsentChanged = () => {
    if (typeof OneTrust === 'undefined') {
      setTimeout(CookieConsent.onConsentChanged, 500);
      return;
    }
    OneTrust.OnConsentChanged(() => {
      CookieConsent.closeOptInScreen();
      $(window).trigger('change.cookieconsent.careuk');
    });

    $(document).on('click', '[data-cookieconsent-dismiss]', (e) => {
      e.preventDefault();
      CookieConsent.closeOptInScreen();
    });
    $(document).on('click', '[data-cookieconsent-consent]', (e) => {
      e.preventDefault();
      OneTrust.ToggleInfoDisplay();
      // CookieConsent.onConsentChanged();
      setTimeout(() => {
        $('[data-optanongroupid="C0003"] [role="tab"]').click();
      }, 250);
    });
  };

  static getCookieOptIn() {
    const value = `; ${document.cookie}`;
    const parts = value.split('; OptanonConsent=');
    if (parts.length !== 2) return false;
    const consent = new URLSearchParams(parts.pop().split(';').shift());
    const groups = consent.get('groups');
    if (groups === null) return false;
    let returnValue = false;
    groups.split(',').forEach((group) => {
      const [groupKey, groupValue] = group.split(':');
      if (groupKey === 'C0003' && groupValue === '1') returnValue = true;
    });
    return returnValue;
  }

  static openOptInScreen() {
    $('html').addClass('cookieConsentOpen');
  }

  static closeOptInScreen() {
    $('html').removeClass('cookieConsentOpen');
  }
}

export function deepestChild($node, selector) {
  return [].slice.call($node.querySelectorAll(selector)).reduce(
    function (deepest, el) {
      let d; let e;
      for (d = 0, e = el; e !== $node; d++, e = e.parentNode);
      return d > deepest.d ? { d: d, el: el } : deepest;
    },
    {
      d: 0,
      el: $node
    },
  ).el;
}

export function debounce(func, context, wait, immediate = false) {
  let timeout;
  return function () {
    let args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function getDistanceFromLatLon(coord1, coord2) {
  const R = 3958.756; // Radius of the earth in miles
  const deg2rad = function (deg) { // Function to translate degree to radian
    return deg * (Math.PI / 180);
  };
  const dLat = deg2rad(coord2.lat - coord1.lat);
  const dLng = deg2rad(coord2.lng - coord1.lng);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(coord1.lat)) * Math.cos(deg2rad(coord2.lat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (R * c); // Distance in miles
}
