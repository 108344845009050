class CTAModal {
  constructor() {
    this.sel = {
      component: '.CTAModal',
      closeButton: '.CTAModal__close',
      noThanks: '.CTAModal__noThanks',
      callUsWrapper: '.CTAModal__call-wrapper',
      callUsButton: '.CTAModal__call-wrapper button',
      chatButton: '#CTAModal-chat',
      callbackButton: '#CTAModal-callback',
      contactPanelModal: '#contactPanelModal',
      requestCallbackButton: '.sendMessage.button',
      messageName: 'input[type="hidden"]#messageName',
      messageVariant: 'input[type="hidden"]#messageVariant'
    };

    this.cookieName = 'KP_CTA_Modal';

    this.displayModal = this.displayModal.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.init = this.init.bind(this);
  }

  displayModal() {
    // Show widget if
    // Cookie is undefined (widget never been shown)
    // obj[messageName] is undefined (it’s a new message, key doesn't exist yet)
    // obj[messageName] !== currentMessageVariant (it’s a new variant)
    const cookieValue = this.getCookie(this.cookieName);
    const messageValues = this.getMessageValues($(this.sel.component));
    const currentMessageName = messageValues.messageName;
    const currentMessageVariant = messageValues.messageVariant;
    
    if (cookieValue === undefined) {
      this.displayTimeout();
    } else {
      const parsedCookieValue = JSON.parse(cookieValue);
      if (!parsedCookieValue[currentMessageName] || parsedCookieValue[currentMessageName] !== currentMessageVariant) {
        this.displayTimeout();
      }
    }
  }

  displayTimeout() {
    const displayTimeout = setTimeout(() => {
      $(this.sel.component).addClass('CTAModal--display');
      $('body').addClass('modal-open');
      clearTimeout(displayTimeout);
    }, 5000);
  }

  bindEvents() {
    // Close modal, NO cookies as modal can re-aapear without conditions
    // Click on close button and on black overlay
    $(this.sel.closeButton).on('click', (e) => {
      $(e.currentTarget).closest(this.sel.component).removeClass('CTAModal--display');
      $('body').removeClass('modal-open');
    });

    $(this.sel.component).on('click', (e) => {
      $(e.currentTarget).removeClass('CTAModal--display');
      $('body').removeClass('modal-open');
    }).children().on('click', function (event) {
      event.stopPropagation();
    });

    // “No thanks” click -> interaction has taken place - NEED COOKIE
    $(this.sel.noThanks).on('click', (e) => {
      $(e.currentTarget).closest(this.sel.component).removeClass('CTAModal--display');
      $('body').removeClass('modal-open');

      this.setCookie($(e.currentTarget));
    });

    // CTA button clicks -> interaction has taken place - NEED COOKIES
    // 1. Call us now
    // On mobile and tablet, only the actual LINK shows, triggering a call with no number reveal.
    // On desktop, the button shows first: once clicked, it’ll reveal the link with the number 
    // and it'll hide itself
    $(this.sel.callUsButton).on('click', (e) => {
      $(e.currentTarget).closest(this.sel.callUsWrapper).addClass('CTAModal--reveal-number');

      this.setCookie($(e.currentTarget));
    });

    // 2. Start a CHAT
    // Closes modal and opens current Livechat
    $(this.sel.chatButton).on('click', (e) => {
      $(e.currentTarget).closest(this.sel.component).removeClass('CTAModal--display');
      $('body').removeClass('modal-open');

      if (typeof LiveChatWidget != 'undefined') {
        LiveChatWidget.on('ready', openChat);
      }

      function openChat(initialData) {
        // Chat Widget is ready
        LiveChatWidget.call('maximize');
      }

      this.setCookie($(e.currentTarget));
    });

    // 3. Request callback
    // Closes modal and opens current Enquiry form panel and loads Enquiry form
    $(this.sel.callbackButton).on('click', (e) => {
      $(e.currentTarget).closest(this.sel.component).removeClass('CTAModal--display');

      $(this.sel.contactPanelModal).modal('show');
      $(this.sel.requestCallbackButton).click();

      this.setCookie($(e.currentTarget));
    });
  }

  // get domain dynamcally, so it works on localhost
  setCookie(eventCurrentTarget) {
    const domain = window.location.hostname.replace('www','');
    const messageValues = this.getMessageValues(eventCurrentTarget);
    const currentMessageName = messageValues.messageName;
    const currentMessageVariant = messageValues.messageVariant;
    
    // needs to get the current cookie value before re-setting it
    // because we need to keep adding message names as keys
    // not update them
    let cookieValue;
    const prevCookieValue = this.getCookie(this.cookieName);
    if (prevCookieValue !== undefined) {
      const parsedCookieValue = JSON.parse(prevCookieValue);
      parsedCookieValue[currentMessageName] = currentMessageVariant;
      cookieValue = JSON.stringify(parsedCookieValue);
    } else {
      let cookieObj = {};
      cookieObj[currentMessageName] = currentMessageVariant;
      cookieValue = JSON.stringify(cookieObj);
    }

    document.cookie = `${this.cookieName}=${cookieValue}; expires=Wed, 31 Dec 2025 23:59:00 UTC; path=/; domain=${domain}`;
  }

  getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
      let [k,v] = el.split('=');
      cookie[k.trim()] = v;
    })
    return cookie[cookieName];
  }

  getMessageValues(eventCurrentTarget) {
    const messageNameValue = eventCurrentTarget.closest(this.sel.component).find(this.sel.messageName).val();
    const messageVariantValue = eventCurrentTarget.closest(this.sel.component).find(this.sel.messageVariant).val();

    const messageValues = {
      messageName: messageNameValue,
      messageVariant: messageVariantValue,
    };

    return messageValues;
  }

  init() {
    if ($(this.sel.component).length <= 0) return;
    this.displayModal();
    this.bindEvents();
  }
}

export default new CTAModal();