// Import components
import Header from './Components/Header';
import Footer from './Components/Footer';
import Carousel from './Components/Carousel';
import StandardContent from './Components/StandardContent';
import Form from './Components/Form';
import CollectionPanel from './Components/CollectionPanel';
import QuickReader from './Components/QuickReader';
import FeaturePanel from './Components/FeaturePanel';
import VideoPanel from './Components/VideoPanel';
import OverlayGallery from './Components/OverlayGallery';
import NewsSearch from './Components/NewsSearch';
import CareHomeList from './Components/CareHomeList';
import VacanciesList from './Components/VacanciesList';
import MapOtherCareHome from './Components/MapOtherCareHome';
import ContactPanel from './Components/ContactPanel';
import CTAModal from './Components/CTAModal';
import HeroPanel from './Components/HeroPanel';
import SocialMedia from './Components/SocialMedia';
import Shortlist from './Components/Shortlist';
import Recent from './Components/Recent';
import Tooltips from './Components/Tooltips';
import LiveChat from './Components/LiveChat';
import NearbyHomesModal from './Components/NearbyHomesModal';
import PaymentForm from './Components/PaymentForm';
import VideoPanelInline from './Components/VideoPanelInline';
import NearestHome from './Components/NearestHome';

$(document).ready(() => {
  $('body').removeClass('nojs');
  try {
    document.createEvent('TouchEvent');
    $('body').addClass('touch');
  } catch (e) {
    // nothing
  }

  // eslint-disable-next-line no-undef
  if (typeof (objectFitImages) === 'function') {
    // eslint-disable-next-line no-undef
    objectFitImages(null, {watchMQ: true});
  }
  // Initiate components
  Header.init();
  Footer.init();
  // disbale modal closing on backdrop click
  $('.modal').attr('data-bs-backdrop', 'static');

  // Setup globally accessable modules that can be initialized inline via widgets as they're added.
  window.widgetModules = {};
  window.widgetModules.carousel = Carousel;
  window.widgetModules.form = Form;
  StandardContent.init();
  window.widgetModules.form.init();
  CollectionPanel.init();
  QuickReader.init();
  window.widgetModules.carousel.init();
  FeaturePanel.init();
  VideoPanel.init();
  OverlayGallery.init();
  NewsSearch.init();
  CareHomeList.init();
  VacanciesList.init();
  MapOtherCareHome.init();
  ContactPanel.init();
  CTAModal.init();
  HeroPanel.init();
  SocialMedia.init();
  Shortlist.init();
  Recent.init();
  Tooltips.init();
  LiveChat.init();
  NearbyHomesModal.init();
  PaymentForm.init();
  VideoPanelInline.init();
  NearestHome.init();
  if (location.hash) {
    setTimeout(() => {
      (document.getElementById(location.hash.slice(1))).scrollIntoView({ behavior: 'instant' });
    }, 1500);
  }
});
