import SearchUtils from './SearchUtils';

class NewsSearch {
  constructor() {
    this.sel = {
      componentTemplateNewsPanel: '#newsPanel__template',
      newsPanelItemsWrapper: '.newsPanel__newsItems',
      newsPanel: '.newsPanel',
      newsPanelMainItem: '.newsPanel__main',
      newsPanelMainItemTile: '.newsPanel__main .article__tile',
      searchForm: '.newsSearchForm',
      inputField: '.newsSearchForm [name="search"]',
      categorySelect: '.newsSearchForm .categorySearch',
      yearSelect: '.newsSearchForm .yearSearch',
      select2Field: '.newsSearchForm .select2',
      searchSubmit: '.newsSearchForm .button__primary',
      componentLoadmore: '.newsPanel .loadmore .button',
      newsPanelContainer: '.newsPanel .container',
      searchFormWrapper: '.searchForm__wrapper',
      searchResetButton: '.newsSearchForm button[type="reset"]',
      autoSuggestField: '.newsSearchForm .typeahead',
      newsPanelWidget: '.newsPanelWidget'
    };

    this.searchKeywords = '';
    this.category = '';
    this.year = '';
    this.documentHeight = $(document).height();
    /* 74px is the height difference changed for navigation when it is fixed and unfixed */
    this.fixPoint = !!$(this.sel.newsPanelMainItem).length
      ? $('.newsPanel__main').offset().top - 74
      : 0;
    this.fixedClass = 'newsPanel__main--fixed';
    this.fixClassAbsolute = 'newsPanel__main--absolute';

    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.populateItem = this.populateItem.bind(this);
    this.populateTemplate = this.populateTemplate.bind(this);
    this.handleNewsScroll = this.handleNewsScroll.bind(this);
    this.mobileBreakpoint = 992;
  }

  bindEvents() {
    if ($(this.sel.searchForm).length > 0) {
      this.setUpAutoComplete();
      $(document).on('typeahead:selected', this.sel.inputField, (e, { id, url }) => {
        if (id === 'viewall') {
          this.formSubmission();
        } else {
          window.location.href = url;
        }
      });

      $(document).on('click', this.sel.searchSubmit, (e) => {
        e.preventDefault();
        this.formSubmission();
      });

      $(document).on('click', this.sel.searchResetButton, () => {
        this.resetForm();
      });

      $(document).on('keypress', this.sel.inputField, (e) => {
        if (e.key === 'Enter') {
          this.formSubmission();
        }
      });

      $(document).on('keyup', this.sel.inputField, (e) => {
        if (!!$(e.target).val() && !!$(this.sel.searchForm).find('.error').length) {
          this.removeFormError();
        }
      });

      $('select').on('change', () => {
        if (!!$(this.sel.searchForm).find('.error').length) {
          this.removeFormError();
        }
      });
    }
    if (!$(this.sel.newsPanelWidget).length) {
      $(window).on('scroll', this.handleNewsScroll);
      $(window).on('resize', this.handleNewsScroll);
    }

    $(document).on('click', this.sel.componentLoadmore, (e) => {
      e.preventDefault();
      SearchUtils.bindEvents(window.newsPanelConfig, this.populateTemplate, $(e.currentTarget).closest('.newsPanel'));
    });
  }

  populateTemplate(data, $currentNewsPanel) {
    const { panelItems, mainItem } = data;
    if (panelItems && !panelItems.length) {
      return false;
    }
    if (mainItem && mainItem.length > 0) {
      mainItem.map((item) => {
        let template = this.populateItem(item);
        $(template).removeClass('col-6 col-md-4');
        $currentNewsPanel.find('.newsPanel__main').first().html(template);
      });
      $currentNewsPanel.find('.newsPanel__newsItems').first().empty();
    }
    panelItems.map((item) => {
      let template = this.populateItem(item);
      $currentNewsPanel.find('.newsPanel__newsItems').first().append(template);
    });
    // Check main tile position*
    if ($(this.sel.newsPanelMainItem).hasClass(this.fixClassAbsolute)) {
      $(this.sel.newsPanelMainItem).removeClass(this.fixClassAbsolute).addClass(this.fixedClass);
    }
  }

  populateItem(item) {
    let templateHtml = $(this.sel.componentTemplateNewsPanel).html();
    let template = $(templateHtml).clone();
    const {
      image,
      imageAltText,
      articleType,
      articleDate,
      articleTitle,
      articleIntro,
      targetUrl,
      quickReader
    } = item;
    $(template).find('img').attr('src', image);
    $(template).find('img').attr('alt', imageAltText);
    $(template).find('.article__tile').attr('href', targetUrl);
    $(template).find('.article__type').html(articleType);
    $(template).find('.article__date').html(articleDate);
    $(template).find('.article__title').html(articleTitle);
    $(template).find('.article__intro').html(articleIntro);

    return template;
  }

  handleNewsScroll() {
    let leftColHeight = $('.newsPanel .newsPanel__main .article__tileWraper').height();
    let rightColHeight = $('.newsPanel .newsPanel__newsItems').height();
    if (
      $(window).scrollTop() >= this.fixPoint &&
      $(window).width() > this.mobileBreakpoint &&
      leftColHeight < rightColHeight
    ) {
      $(this.sel.newsPanelMainItem).removeClass(this.fixClassAbsolute);
      $(this.sel.newsPanelMainItem).addClass(this.fixedClass);
      $(this.sel.newsPanelMainItemTile).css('width', $(this.sel.newsPanelMainItem).width());
    } else {
      $(this.sel.newsPanelMainItem).removeClass(this.fixedClass).removeClass(this.fixClassAbsolute);
    }
    /* when the left main tile and right news tiles bottom reached the same bottom line fixed position changed to absolute position */
    if (
      rightColHeight - leftColHeight < $(window).scrollTop() - this.fixPoint &&
      $(this.sel.newsPanelMainItem).hasClass(this.fixedClass)
    ) {
      $(this.sel.newsPanelMainItem).removeClass(this.fixedClass).addClass(this.fixClassAbsolute);
    }
  }

  removeFormError() {
    if (!!$(this.sel.searchForm).find('.error').length) {
      $(this.sel.searchForm).find('.error').removeClass('error');
      $('.error-info').remove();
    }
  }

  // eslint-disable-next-line consistent-return
  formSubmission() {
    this.searchKeywords = $(this.sel.inputField).val() ? $(this.sel.inputField).val().trim() : '';
    this.category = $(this.sel.categorySelect).val();
    this.year = $(this.sel.yearSelect).val();

    if (
      !this.searchKeywords &&
      !this.category &&
      !this.year &&
      !$(this.sel.searchForm).find('.error').length
    ) {
      $(this.sel.inputField).closest('.inputIconWrapper').addClass('error');
      $(this.sel.select2Field).addClass('error');
      $(this.sel.searchFormWrapper).append(
        '<p class="error-info">Please fill in the search criteria.<p>',
      );
      return false;
    }

    if (!$(this.sel.searchForm).find('.error').length) {
      let baseUrl = location.protocol + '//' + location.host + location.pathname;
      let paramsObj = {
        search: this.searchKeywords,
        category: this.category,
        year: this.year
      };

      const paramsString = Object.keys(paramsObj)
        .filter((key) => !!paramsObj[key])
        .map((key) => `${key}=${paramsObj[key]}`)
        .join('&');

      window.location.href = `${baseUrl}?` + paramsString;
    }
  }

  // reset from and change url with no params but not refresh the page
  resetForm() {
    $(this.sel.inputField).val('');
    $('select').val('').trigger('change');
    this.searchKeywords = '';
    this.category = '';
    this.year = '';
    const url = new URL(window.location.href);
    url.search = '';
    window.history.pushState({}, '', url.toString());
  }

  // Initalise typeahead.js library and configure
  setUpAutoComplete() {
    const results = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url:
          window.searchAutoPanelConfig.url +
          'QUERY' +
          '/' +
          window.searchAutoPanelConfig.articleCategories +
          '/' +
          window.searchAutoPanelConfig.categoryNodeAlias,
        wildcard: 'QUERY'
      },
      limit: Infinity
    });
    results.initialize();

    $(this.sel.autoSuggestField).typeahead(
      {
        highlight: true,
        hint: true,
        minLength: 2
      },
      {
        name: 'results',
        source: results.ttAdapter(),
        limit: Infinity,
        templates: {
          suggestion: this.suggestionTemplate
        },
        display: ({ id, title, url }) => (id === 'viewall' ? url : title)
      },
    );
  }

  // eslint-disable-next-line consistent-return
  suggestionTemplate(data) {
    const { id, title, url } = data;
    return id === 'viewall'
      ? `<div id="${id}" class="item" data-keyword="${url}"><b>${title}</b></div>`
      : `<div class="item" data-keyword="${url}">${title}</div>`;
  }

  init() {
    if ($(this.sel.newsPanel).length <= 0) return;
    this.bindEvents();
  }
}

export default new NewsSearch();
