import { convertLatLng } from './Google/GoogleMapHelpers';

class MapOtherCareHome {
  constructor() {
    this.sel = {
      otherCareHomeRow: '.otherCareHomeRow',
      map: '#map--OtherCareHome'
    };

    this.locations = [];
  }

  bindEvents() {
    if ($(this.sel.otherCareHomeRow).length) {
      return;
      this.locations = window.careHomeList.careHomeItems
        .sort((x, y) => {
          return x.distance - y.distance;
        })
        .map((item) => convertLatLng(item.location));

      this.loadedMap = false;
      $(window).on('scroll', (evt)=>{
        const distanceBeforeMapOnScreen = $(this.sel.map)[0].getBoundingClientRect().top - window.innerHeight;
        if (distanceBeforeMapOnScreen < 250) {
          $(window).off(evt);
          (new LocationMap()).addMap({
            loadedLoc: this.locations,
            mapId: 'map--OtherCareHome'
          });

          if (this.locations.length === 1) {
            $(this.sel.map).append(
              `<a class='view__map' target='_blank' href='https://www.google.com/maps/search/?api=1&query=${this.locations[0].lat},${this.locations[0].lng}'>View on Google maps</a>`
            );
          }
        }
      });
    }
  }

  init() {
    this.bindEvents();
  }
}

export default new MapOtherCareHome();
