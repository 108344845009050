class CareHomeData {
  constructor() {
    this.sel = {
      data: '#careHomes--metadata'
    };
  }

  validate() {
    if ($(this.sel.data).length <= 0) throw 'No data found';
    if ($(this.sel.data).length > 1) throw 'More then 1 data object found';
    return true;
  }

  getCurrentHome() {
    try {
      if (this.validate()) {
        const guid = $(this.sel.data).data('current');
        const location = $(this.sel.data).data('all').filter(locations => locations.guid === guid);
        if (location) {
          return location[0]; // Return first as an object - there should only be 1 current home
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  getAllHomes(filter = { currentHome: false }) {
    try {
      if (this.validate()) {
        if (filter.currentHome === true) {
          const guid = $(this.sel.data).data('current');
          return $(this.sel.data).data('all').filter(locations => locations.guid !== guid);
        } else {
          return $(this.sel.data).data('all');
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export default new CareHomeData();
