class ComponentWrapper {
  constructor(selector, component) {
      this.selector = selector;
      this.Component = component;
      this.components = [];
      this.init = this.init.bind(this);
  }

  init() {
      const $components = document.querySelectorAll(this.selector);
      if ($components.length > 0) {
          for (let i = 0; i < $components.length; i++) this.components.push(new this.Component($components[i]));
      }
  }
}

export default ComponentWrapper;
