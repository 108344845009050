class Pagination {
  constructor(array, pageLength = 10) {
    this.array = array;
    this.currentPage = null;
    this.pageLength = pageLength;
    this.currentIndex = 0;
  }

  getPage(numberOfItems = false) {
    let pageLength = this.pageLength;
    if (numberOfItems) {
      pageLength = numberOfItems;
    }
    const start = this.currentIndex;
    const end = start + pageLength;
    const data = this.array.slice(start, end);
    this.currentPage = data;
    this.currentIndex = end;
    return {
      hasMore: (end < this.array.length),
      data: data
    };
  }

  getCurrentPage() {
    return {
      hasMore: (this.currentIndex < this.array.length),
      data: this.currentPage
    };
  }

  reset() {
    this.currentIndex = 0;
  }
}

export default Pagination;
