import { GoogleMap } from './Google/GoogleMap';
import CareHomeData from './CareHomeData';
import { randomString } from '../helpers';
import Pagination from './Pagination';

class NearbyHomesModal {

  constructor() {
    this.instance = (() => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)))();
    this.sel = {
      nearbyHomesModal: 'nearbyHomesModal',
      nearbyHomesOnPage: 'nearbyHomesOnPage'
    };
    this.data = null;

    this.GoogleMapModal = new GoogleMap();
    this.GoogleMapInline = new GoogleMap();

    this.mapActivated = false;

    this.initModalMap = this.initModalMap.bind(this);
    this.initOnPageMap = this.initOnPageMap.bind(this);
  }

  getData(prefix = '') {
    let thisData = (() => {
      const data = {
        currentHome: CareHomeData.getCurrentHome(),
        allHomes: new Pagination(CareHomeData.getAllHomes({ currentHome: true }), 10),
      };
      let firstPage = true;
      const getPage = () => {
        if (firstPage) {
          firstPage = false;
          return data.allHomes.getPage(9);
        }
        return data.allHomes.getPage();
      };
      const reset = () => {
        data.allHomes.reset();
        firstPage = true;
        $(window).trigger(`${prefix}${this.instance}.datareset.nearbyhomes.careuk`);
      };
      $(window).on(`${prefix}${this.instance}.nextpagedata.nearbyhomes.careuk`, () => {
        let pageData = getPage();
        $(window).trigger(`${prefix}${this.instance}.pageddata.nearbyhomes.careuk`, pageData);
      });
      return {
        currentHome: data.currentHome,
        getPage: getPage,
        reset: reset
      };
    })();
    return thisData;
  }

  initModalMap() {
    const element = document.getElementById(this.sel.nearbyHomesModal);
    const id = randomString(32, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    const template = this.createMapContainer(id, 'current-home');
    $(`#${this.sel.nearbyHomesModal}`).find('.googleMap').append(template);
    element.addEventListener('show.bs.modal', () => {
      const data = this.getData('modal.');
      if (!this.mapActivated) {
        const page = data.getPage();
        this.GoogleMapModal.init(id, page.data, data.currentHome, `modal.${this.instance}`);
        this.renderDesktopList(page.data, this.sel.nearbyHomesModal);
        this.renderMobileList(page.data, this.sel.nearbyHomesModal);
        this.mapActivated = true;
        $(window).on(`modal.${this.instance}.pageddata.nearbyhomes.careuk`, (e, pageData) => {
          const homes = pageData.data;
          if (homes.length > 0) {
            this.renderDesktopList(homes, this.sel.nearbyHomesModal);
            this.renderMobileList(homes, this.sel.nearbyHomesModal);
          }
        });
      } else {
        this.GoogleMapModal.reInit();
      }
    });

    element.addEventListener('hidden.bs.modal', () => {
      this.GoogleMapModal.resetMap();
    });
  }

  initOnPageMap() {
    const id = randomString(32, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    const template = this.createMapContainer(id, 'nearby-homes');
    $(`#${this.sel.nearbyHomesOnPage}`).find('.googleMap').append(template);
    const data = this.getData('page.');
    const page = data.getPage();
    this.GoogleMapInline.init(id, page.data, data.currentHome, `page.${this.instance}`);
    this.renderDesktopList(page.data, this.sel.nearbyHomesOnPage);
    this.renderMobileList(page.data, this.sel.nearbyHomesOnPage);

    $(window).on(`page.${this.instance}.pageddata.nearbyhomes.careuk`, (e, pageData) => {
      const homes = pageData.data;
      if (homes.length > 0) {
        this.renderDesktopList(homes, this.sel.nearbyHomesOnPage);
        this.renderMobileList(homes, this.sel.nearbyHomesOnPage);
      }
    });
  }

  renderDesktopList(data, container) {
    data.forEach(home => {
      const {
        guid,
        pin,
        location,
        area,
        postcode,
        image,
        imageAltText,
        name,
        rating,
        homeLink,
        homeLinkTitle,
        mapLink,
        mapLinkTitle,
        pdfLink,
        pdfLinkTitle,
        county,
        tooltipShortlistAdd,
        tooltipShortlistRemove
      } = home;
      const templateHtml = $('#nearbyHomesListContent__template').html();
      const template = $(templateHtml).clone();
      $(template).attr('data-pin', `${pin}`);
      $(template).find('.imageContainer img').attr('src', image);
      $(template).find('.imageContainer img').attr('alt', imageAltText);
      $(template).find('.careHomeItem__name').html(name);
      $(template).find('.pin > span').html(pin);
      $(template).find('.pin').attr('data-location', location);
      $(template).find('.googleMapSpanToLocation').attr('data-location', location);
      $(template).find('[data-shortlist-toggle]').attr('data-shortlist-toggle', guid);
      $(template).find('[data-shortlist-name]').attr('data-shortlist-name', name);
      $(template).find('[data-shortlist-county]').attr('data-shortlist-county', county);
      $(template).find('[data-tooltip-add]').attr('data-tooltip-add', tooltipShortlistAdd);
      $(template).find('[data-tooltip-remove]').attr('data-tooltip-remove', tooltipShortlistRemove);
      $(template).find('[data-tooltip-add]').attr('title', tooltipShortlistAdd);
      $(template).find('.nearbyHomesLocationCard--addr').html(`${area} ${postcode}`);
      $(template).find('.link_view').attr('href', homeLink);
      $(template).find('.link_view').attr('title', homeLinkTitle);

      function removePdf() {
        $(template).find('.link_spacer').remove();
        $(template).find('.link_pdf').remove();
      }

      function removeGoogle() {
        $(template).find('.link_spacer').remove();
        $(template).find('.link_google').remove();
      }

      if(!mapLink && !pdfLink) {
        $(template).find('.directions').remove();
        removePdf();
        removeGoogle();
      } else if (mapLink && !pdfLink) {
        $(template).find('.link_google').attr('href', mapLink);
        $(template).find('.link_google').attr('title', mapLinkTitle);
        removePdf();
      } else if (!mapLink && pdfLink) {
        $(template).find('.link_pdf').attr('href', pdfLink);
        $(template).find('.link_pdf').attr('title', pdfLinkTitle);
        removeGoogle();
      } else {
        $(template).find('.link_google').attr('href', mapLink);
        $(template).find('.link_google').attr('title', mapLinkTitle);
        $(template).find('.link_pdf').attr('href', pdfLink);
        $(template).find('.link_pdf').attr('title', pdfLinkTitle);
      }

      $(`#${container}`).find('.list').append($(template));
    });
  }

  renderMobileList(data, container) {
    data.forEach(home => {
      const {
        guid,
        pin,
        location,
        area,
        postcode,
        image,
        imageAltText,
        name,
        rating,
        homeLink,
        homeLinkTitle,
        mapLink,
        mapLinkTitle,
        pdfLink,
        pdfLinkTitle,
        county,
        tooltipShortlistAdd,
        tooltipShortlistRemove
      } = home;
      const templateHtml = $('#nearbyHomesCarouselContent__template').html();
      const template = $(templateHtml).clone();
      $(template).find('.imageContainer img').attr('src', image);
      $(template).find('.imageContainer img').attr('alt', imageAltText);
      $(template).find('.careHomeItem__name').attr('href', homeLink);
      $(template).find('.careHomeItem__name').attr('title', name);
      $(template).find('.careHomeItem__name').html(name);
      $(template).find('.pin > span').html(pin);
      $(template).find('.pin').attr('data-location', location);
      $(template).find('[data-shortlist-toggle]').attr('data-shortlist-toggle', guid);
      $(template).find('[data-shortlist-name]').attr('data-shortlist-name', name);
      $(template).find('[data-shortlist-county]').attr('data-shortlist-county', county);
      $(template).find('[data-tooltip-add]').attr('data-tooltip-add', tooltipShortlistAdd);
      $(template).find('[data-tooltip-remove]').attr('data-tooltip-remove', tooltipShortlistRemove);
      $(template).find('[data-tooltip-add]').attr('title', tooltipShortlistAdd);
      $(template).find('.careHomeItem__title').html(`${area} ${postcode}`);
      $(template).find('.rating').append(rating);
      $(template).find('.link_view').attr('href', homeLink);
      $(template).find('.link_view').attr('title', homeLinkTitle);

      function removePdf() {
        $(template).find('.link_spacer').remove();
        $(template).find('.link_pdf').remove();
      }

      function removeGoogle() {
        $(template).find('.link_spacer').remove();
        $(template).find('.link_google').remove();
      }

      if(!mapLink && !pdfLink) {
        $(template).find('.directions').remove();
        removePdf();
        removeGoogle();
      } else if (mapLink && !pdfLink) {
        $(template).find('.link_google').attr('href', mapLink);
        $(template).find('.link_google').attr('title', mapLinkTitle);
        removePdf();
      } else if (!mapLink && pdfLink) {
        $(template).find('.link_pdf').attr('href', pdfLink);
        $(template).find('.link_pdf').attr('title', pdfLinkTitle);
        removeGoogle();
      } else {
        $(template).find('.link_google').attr('href', mapLink);
        $(template).find('.link_google').attr('title', mapLinkTitle);
        $(template).find('.link_pdf').attr('href', pdfLink);
        $(template).find('.link_pdf').attr('title', pdfLinkTitle);
      }

      $(`#${container}`).find('.carouselNearbyHomes__sliders').append($(template));
    });

    const element = $('.carouselNearbyHomes__sliders');
    if (!!element.length) {
      let autoplay = element.attr('data-autoplay') !== undefined;
      let autoplaySpeed = autoplay ? parseInt(element.attr('data-autoplay')) : 0;
      element.not('.slick-initialized').slick({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay,
        autoplaySpeed
      });
    }
  }

  createMapContainer(id, mapType) {
    const templateHtml = $('#nearbyHomesGoogleMap__template').html();
    const template = $(templateHtml).clone();
    $(template).attr('id', id);
    $(template).attr('data-map-type', mapType);
    return template;
  }

  init() {
    if ($(`#${this.sel.nearbyHomesModal}`).length >= 1) {
      this.initModalMap();
    }
    if ($(`#${this.sel.nearbyHomesOnPage}`).length >= 1) {
      this.initOnPageMap();
    }
  }
}

export default new NearbyHomesModal();
