class CollectionPanel {
  constructor() {
    this.sel = {
      component: '.collectionPanel--people',
      componentItemWrapper: '.collectionPanel__itemWrapper',
      componentItem: '.collectionPanel__item',
      componentInfo: '.collectionPanel__expandContent',
      componentLoadmore: '.collectionPanel .loadmore .button',
      componentTemplatePeople: '#collectionPanelPeople__template',
      componentTemplateAwards: '#collectionPanelAwards__template',
      componentLoadmoreContainerPeople: '.collectionPanel--people .custom__gutter',
      componentLoadmoreContainerAwards: '.collectionPanel--awards .custom__gutter',
      peoplePanelLoadmore: '.collectionPanel--people .loadmore .button',
      awardsPanelLoadmore: '.collectionPanel--awards .loadmore .button'
    };

    this.increaseBy = 6;
    this.currentLoadCount = 0;
    this.bindEvents = this.bindEvents.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);

    /* Loadmore */
    this.init = this.init.bind(this);
    this.constructURL = this.constructURL.bind(this);
    this.loadmore = this.loadmore.bind(this);
    this.populateTemplate = this.populateTemplate.bind(this);
    this.constructLoadmore = this.constructLoadmore.bind(this);
    this.mobileBreakpoint = 992;
  }

  bindEvents() {
    this.toggleInfo();
    $(document).on('click', this.sel.componentLoadmore, (e) => {
      e.preventDefault();
      let type = $(e.target).parent('.loadmore').attr('data-type');
      let config = window[`${type}Config`];
      let url = this.constructURL(config, this.currentLoadCount);
      this.loadmore(url, type);
    });

    this.setEqualHeight();
  }

  toggleInfo() {
    $(document).on('click', this.sel.componentItemWrapper, (e) => {
      const $itemWrapper = $(e.currentTarget);
      if ($itemWrapper.hasClass('show')) {
        $(this.sel.componentItemWrapper).removeClass('show');
        $itemWrapper.removeClass('show');
      } else {
        $(this.sel.componentItemWrapper).removeClass('show');
        $itemWrapper.addClass('show');
      }
    });
  }

  constructURL(configObj, currentLoadCount) {
    // careukapi/{controller}/sr/{cultureCode}/{siteName}/{sortOrder}/{skip}
    const { url, culture, skip, currentDocumentNodeGuid, documentType } = configObj;
    let currentSkip = skip + currentLoadCount * this.increaseBy;
    return `${url}/${currentDocumentNodeGuid}/${culture}/${currentSkip}`;
  }
  loadmore(url, type) {
    $.ajax({
      url: url
    }).done((data) => {
      this.currentLoadCount = this.currentLoadCount + 1;
      const { collectionPanel, hasMore } = data;
      collectionPanel.map((item) => {
        this.populateTemplate(item, type);
      });
      this.constructLoadmore(hasMore, type);

      $(this.sel.componentItem).removeAttr('style');
      this.setEqualHeight();
    });
  }

  populateTemplate(item, type) {
    if (type === 'collectionPanelPeople') {
      let templateHtml = $(this.sel.componentTemplatePeople).html();
      let template = $(templateHtml).clone();
      const { imageMobile, imageDesktop, imageAltText, name, title, quote, details, badges } = item;
      $(template).find('picture').attr('srcset', imageDesktop);
      $(template).find('img').attr('src', imageMobile);
      $(template).find('img').attr('alt', imageAltText);
      $(template).find('.person__name').html(name);
      $(template).find('.person__title').html(title);
      if (!!badges && badges.length > 0) {
        const badgesHtml = badges.map(({img, altText}) => `<img src="../img/${img}" alt="${altText}">`).join('');
        $(template).find('.person__title').after(`<div class="person__badges">${badgesHtml}</div>`);
      }
      $(template).find('.inline__quote--content').html(quote);
      $(template).find('.collectionPanel__details').html(details);
      $(this.sel.componentLoadmoreContainerPeople).append(template);
      return false;
    }
    let templateHtml = $(this.sel.componentTemplateAwards).html();
    let template = $(templateHtml).clone();
    const { image, imageAltText, awards, quote, details } = item;
    $(template).find('img').attr('src', image);
    $(template).find('img').attr('alt', imageAltText);
    $(template).find('.collectionPanel__awardsDetail').html(awards);
    $(template).find('.inline__quote--content').html(quote);
    $(template).find('.collectionPanel__details').html(details);
    $(this.sel.componentLoadmoreContainerAwards).append(template);
  }

  constructLoadmore(hasmore, type) {
    if (!hasmore) {
      type === 'collectionPanelPeople'
        ? $(this.sel.peoplePanelLoadmore).hide()
        : $(this.sel.awardsPanelLoadmore).hide();
    }
  }

  setEqualHeight() {
    $(this.sel.component).each( (x, item) => {
      const itemStyle = $(item).attr('style');
      if (itemStyle && itemStyle.includes('height')) return;
      const $peopleItems = $(item).find(this.sel.componentItem);
      for (let i = 0; i < $peopleItems.length; i += 3) {
        let groupItems = $peopleItems.slice(i, i + 3);
        let maxHeight = 0;

        groupItems.each( (y, groupItem) => {
          let height = $(groupItem).height();
          if (height > maxHeight) {
            maxHeight = height;
          }
        });

        groupItems.height(maxHeight);
      }
    });
    $('.person__name').each((n, personItem) => {
      const personTitle = $(personItem).next('.person__title');
      if (personTitle.length && !personTitle.parent('.personInfo').length) {
        $(personItem).add(personTitle).wrapAll('<div class="personInfo"></div>');
      }
    });
  }

  init() {
    if ($(this.sel.component).length < 0) return;
    this.bindEvents();
    let resizeTimer;
    $(window).on('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        if ($(window).width() > this.mobileBreakpoint) {
          this.setEqualHeight();
        } else {
          $(this.sel.componentItem).removeAttr('style');
        }
      }, 100);
    });
  }
}

export default new CollectionPanel();
