import { CookieConsent } from "../helpers";
import ComponentWrapper from "./ComponentWrapper";

class PaymentForm {
  constructor(element) {
    this.$element = $(element);
    this.sel = {
      checkboxes: '.ktc-default-section input[type="checkbox"]',
      checkboxClickTarget: '.ktc-default-section'
    };

    this.bindEvents.bind(this)();
  }

  bindEvents() {
    this.$element.on('click', this.sel.checkboxClickTarget, (e) => {
      if (!CookieConsent.getCookieOptIn()) {
        e.preventDefault();
        e.stopPropagation();
        CookieConsent.openOptInScreen();
        const clickedElement = e.target;
        const handleConsentChange = () => {
          if (!CookieConsent.getCookieOptIn()) return;
          let $clickedElement = $(clickedElement);
          if (!$clickedElement.is('label')) {
            $clickedElement = $clickedElement.closest('label');
          }
          $clickedElement.click();
          $(window).off('change.cookieconsent.careuk', handleConsentChange);
        };
        $(window).on('change.cookieconsent.careuk', handleConsentChange);
        return;
      }
    });
    if (this.$element.find(this.sel.checkboxes).length > 1) {
      this.$element.on('change', this.sel.checkboxes, (e) => {
        let checkboxes = $(e.target).closest('.col-12').find(this.sel.checkboxes);
        checkboxes.prop('checked', false);
        $(e.target).prop('checked', true);
      });
    }
  }
}

export default new ComponentWrapper('.paymentForm', PaymentForm);
