import Player from '@vimeo/player';

class VideoPanelInline {
  constructor() {
    this.sel = {
      videoPanelInline: '.videoPanelInline',
      videoPanelModal: '.videoPanelInline__modal',
      videoPanelModalCloseBtn: '.videoPanelInline__modal-button',
      videoPlayBtn: '.videoPanelInline__play',
      carouselVideoPlayer: 'carouselVideoImage',
      carouselArrows: '.slick-arrow'
    };

    this.mobileBreakpoint = 992;
    this.player = [];
    this.currentPlayerIndex = null;
    this.currentPlayerModal = null;
    this.playType = {
      inline: 'inline',
      modal: 'modal'
    };
  }

  bindEvents() {
    this.playVideo();
    this.pauseVideo();
    this.closePlayerModel();
  }

  playVideo() {
    $(this.sel.videoPlayBtn).on('click', (e) => {
      e.stopPropagation();
      const button = e.currentTarget;
      let playtype = $(button).data('playtype');
      const component =  $(button).closest('.videoPanelInline');
      const iframe = component.find('.videoPanelInline__iframe');
      const id = iframe.data('src');
      iframe.attr('src', id);

      // always play modal for mobile
      if ($(document).width() < this.mobileBreakpoint) playtype = 'modal';

      if (playtype === this.playType.inline) {
        component.addClass('videoPanelInline--viewing');
        const currentPlayer = new Player(iframe);
        this.player = [...this.player, currentPlayer];
        currentPlayer.play();
        this.checkPlayerIndex();
      }

      if (playtype === this.playType.modal) {
        if (this.currentPlayerIndex !== null) {
          this.player[this.currentPlayerIndex].pause();
        }
        $(this.sel.videoPanelModal).removeClass('d-none');
        $(component).addClass('currentVideoModal');
        $(this.sel.videoPanelModal).find('.videoPanelInline__video').append(iframe[0]);
        this.currentPlayerModal = new Player(iframe);
        this.currentPlayerModal.play();
      }
    });
  }

  checkPlayerIndex() {
    this.player.forEach((player, index) => {
      player.on('play', () => {
        this.currentPlayerIndex = index;
      });
    });
  }

  pauseVideo() {
    $(this.sel.carouselArrows).on('click', () => {
      if (this.currentPlayerIndex !== null) {
        this.player[this.currentPlayerIndex].pause();
      }
    });

    $('.slick-initialized').on('swipe', () => {
      if (this.currentPlayerIndex !== null) {
        this.player[this.currentPlayerIndex].pause();
      }
    });
  }

  closePlayerModel() {
    $(this.sel.videoPanelModalCloseBtn).on('click', () => {
      $(this.sel.videoPanelModal).addClass('d-none');
      $('.videoPanelInline__modal iframe').removeAttr('src data-ready').empty();
      $('.currentVideoModal .videoPanelInline__video').append(this.currentPlayerModal.element);
      $('.currentVideoModal').removeClass('currentVideoModal');
    });
  }

  init() {
    if ($(this.sel.videoPanelInline).length > 0) {
      this.bindEvents();
    }
  }
}

export default new VideoPanelInline();
