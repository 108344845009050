import { debounce } from '../helpers';
import {
  buttonTemplate,
  isBoundWithinBound,
  searchThisAreaButtonContent,
  pink
} from './Google/GoogleMapHelpers';

export default class LocationMap {
  constructor(isDebug = false) {
    this.sel = {
      mapWrapper: '.map__wrapper',
      pin: '.icon-map-pin',
      mapOverlayModal: '#mapOverlayModal',
      careHomeOverlayModal: '#carehomeOverlayModal',
      loadmore: '.loadmore .button'
    };

    this.isDebug = isDebug;
    this.modalTrigger = '';

    this.geocoder = null;
    this.map = null;
    this.mapBounds = null;
    this.searchThisAreaShowing = false;
    this.markers = [];
    this.addMap = this.addMap.bind(this);
    this.addMarkers = this.addMarkers.bind(this);
    this.positionMap = this.positionMap.bind(this);
    this.loadOverlay = this.loadOverlay.bind(this);
    this.triggerMapOverlay = this.triggerMapOverlay.bind(this);
    this.mapLoadAttempts = 0;

    this.constructLoadmore = this.constructLoadmore.bind(this);
  }

  addMap({ currentLoc, loadedLoc, mapId, scrollItemClass = 'careHomeItem' }) {
    if (typeof google === 'undefined') {
      if (this.mapLoadAttempts >= 20) return;
      setTimeout(() => {
        this.addMap({
          currentLoc,
          loadedLoc,
          mapId
        });
      }, 100);
      this.mapLoadAttempts++;
      return;
    }
    let mapElement = mapId ? document.getElementById(mapId) : document.getElementById('map');
    if (!mapElement || mapElement === null) return;
    this.map = new google.maps.Map(mapElement, {
      zoom: 15,
      center: currentLoc,
      draggable: true,
      animation: google.maps.Animation.DROP,
      optimized: false
    });
    // If there are already markers, set their map to this map
    if (this.markers.length > 0) {
      this.markers.forEach((marker) => marker.setMap(this.map));
    }
    const bounds = new google.maps.LatLngBounds();
    !!loadedLoc
      ? this.addMarkers(loadedLoc, scrollItemClass)
      : this.addMarkers([currentLoc], scrollItemClass);

    if (!!loadedLoc && !!loadedLoc.length) {
      this.markers.map((item) => {
        bounds.extend(item.position);
        item.setMap(this.map);
      });
      this.map.fitBounds(bounds);
      google.maps.event.addListenerOnce(this.map, 'bounds_changed', function () {
        if (this.getZoom() > 15) {
          this.setZoom(15);
        }
      });
    }
    if (scrollItemClass !== 'vacanciesListAlt__perHome') {
      setTimeout(
        (self) => {
          self.mapBounds = self.map.getBounds();
          const debouncedCallback = debounce(
            () => {
              const newBounds = self.map.getBounds();
              if (
                self.mapBounds.equals(newBounds) ||
                isBoundWithinBound(self.mapBounds, newBounds)
              ) {
                return;
              }
              self.showSearchThisArea();
            },
            self,
            150,
          );

          self.map.addListener('bounds_changed', debouncedCallback);
          this.map.addListener('zoom_changed', () => {
            $(mapElement).trigger('zoomchanged.locationmap.careuk');
          });
        },
        1000,
        this,
      );
    }
  }

  showSearchThisArea() {
    if (this.searchThisAreaShowing || window.disableDynamicMaps) return;
    this.searchThisAreaShowing = true;
    const div = document.createElement('div');
    div.id = 'locationMap__searchThisAreaBtn';
    const searchBtn = buttonTemplate();
    searchBtn.style.border = `2px solid ${pink}`;
    searchBtn.style.color = `${pink}`;
    searchBtn.innerHTML = searchThisAreaButtonContent();
    searchBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.doSearchFromCentre();
      this.hideSearchThisArea();
    });
    div.appendChild(searchBtn);
    this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(div);
  }

  hideSearchThisArea() {
    if (!this.searchThisAreaShowing || window.disableDynamicMaps) return;
    this.searchThisAreaShowing = false;
    document.getElementById('locationMap__searchThisAreaBtn').remove();
  }

  doSearchFromCentre(label = 'Centre of map') {
    $('.careHomeSearchKeywords.typeahead').typeahead('val', label);
    $('.careHomeSearchForm [type="text"]').trigger('keyup');
    $(window).trigger('update.search.carehomelist.careuk', this.map.getBounds());
  }

  clearMarkers() {
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];
  }

  addMarkers(locations, scrollItemClass = 'careHomeItem') {
    const existingMarkers = this.markers.length;
    const bounds = new google.maps.LatLngBounds();
    locations.map((location, index) => {
      const totalIndex = existingMarkers + index;
      let mapMarker = new google.maps.Marker({
        position: location,
        map: this.map,
        label: { text: (totalIndex + 1).toString(), color: 'white' },
        icon: {
          path: 'M13 0C5.8 0 0 5.8 0 13c0 3.2 1.2 6.1 3.1 8.4L13 33l9.9-11.6c1.9-2.3 3.1-5.2 3.1-8.4 0-7.2-5.8-13-13-13z',
          fillColor: '#D7088B',
          fillOpacity: 1,
          strokeWeight: 0,
          scale: 1,
          labelOrigin: new google.maps.Point(13, 13),
          anchor: new google.maps.Point(13, 33)
        },
        careHomeIndex: totalIndex,
        careHomeLatLng: `${location.lat}${location.lng}`,
        optimized: false,
        title: `location-${location.pin}`
      });

      this.markers.push(mapMarker);
      bounds.extend(mapMarker.getPosition());

      mapMarker.addListener('click', () => {
        this.map.setCenter(mapMarker.position);
        if (window.disableDynamicMaps) return;
        setTimeout(() => {
          if (scrollItemClass !== 'vacanciesListAlt__perHome') {
            this.map.setCenter(mapMarker.position);
            this.doSearchFromCentre();
            const $clickedCareHome = $(
              '.careHomeItem[data-lat-lng="' + mapMarker.careHomeLatLng + '"]',
            );
            if ($clickedCareHome) {
              document.querySelector('input#careHomeSearch').value =
                $clickedCareHome.attr('data-town');
              $('.careHomeItem.feature').removeClass('highlight');
              $clickedCareHome.addClass('highlight');
            }
          } else {
            // scroll to the tile in the
            const $clickedItem = $(
              `.${scrollItemClass}:nth-child(` + (parseInt(mapMarker.careHomeIndex, 10) + 1) + ')',
            );
            if ($clickedItem.length > 0) {
              $(`.${scrollItemClass}.highlight`).removeClass('highlight');
              $clickedItem.addClass('highlight');
              $clickedItem[0].scrollIntoView({ block: 'center', inline: 'nearest' });
            }
          }
        }, 50);
      });
    });

    if (scrollItemClass === 'vacanciesListAlt__perHome') {
      this.map.fitBounds(bounds, 10);
    }
  }

  positionMap(element) {
    let left = $(element).offset().left + $(element).outerWidth() + 60;
    $(this.sel.mapWrapper).css('left', left);
  }

  loadOverlay(element) {
    let mapModal = new bootstrap.Modal($(element)[0]);
    mapModal.show();
  }

  triggerMapOverlay({ currentLoc, loadedLoc, mapId = 'mapOverlay' }) {
    const loadedLocCopy = [...loadedLoc];
    this.addMap({
      currentLoc,
      loadedLoc: loadedLocCopy,
      mapId
    });
  }

  addModelTriger(trigger, loadMobileMap = null) {
    $(document).on('click', trigger, () => {
      if (!!loadMobileMap) {
        loadMobileMap();
      }
      this.loadOverlay(this.sel.mapOverlayModal);
    });
  }

  constructLoadmore(nomore) {
    if (nomore) {
      $(this.sel.loadmore).hide();
    }
  }

  getCentre() {
    if (this.map == null) return false;
    return this.map.getCenter().toJSON();
  }
}
