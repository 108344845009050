class SearchUtils {
  constructor() {
    this.sel = {
      componentLoadmore: '.loadmore .button',
      newsPanel: '.newsPanel'
    };
    this.increaseBy = window.newsPanelConfig ? parseInt($(this.sel.newsPanel).attr('data-increaseby'), 10) || 9 : 6;
    this.currentLoadCount = 0;
    this.bindEvents = this.bindEvents.bind(this);

    /* Loadmore */
    this.constructURL = this.constructURL.bind(this);
    this.loadmore = this.loadmore.bind(this);
    this.constructLoadmore = this.constructLoadmore.bind(this);
  }

  bindEvents(config, populateTemplate, currentNewsPanel) {
    let url = this.constructURL({
      config,
      increaseBy: this.increaseBy,
      currentLoadCount: this.currentLoadCount,
      currentNewsPanel
    });
    this.loadmore(url, populateTemplate, currentNewsPanel);
  }

  constructURL({ config, currentLoadCount, increaseBy, currentNewsPanel }) {
    // /api/articles/{culture}/{skip}/{currentDocumentNodeGuid}/{searchText}/{year}/{categoryNodeAlias}/{careHomeNodeGuid}
    const {
      url,
      culture,
      currentDocumentNodeGuid,
      searchText,
      year,
      categoryNodeAlias,
      careHomeNodeGuid,
      tagToShow
    } = config;

    let currentSkip = currentNewsPanel.find('.newsPanel__main').first().find('.article__tile').length
    + currentNewsPanel.find('.newsPanel__newsItems').first().find('.article__tile').length;

    let constructedUrl = tagToShow ? `${url}/${culture}/${currentSkip}/${currentDocumentNodeGuid}/${searchText}/${year}/${categoryNodeAlias}/${careHomeNodeGuid}/${tagToShow}`
      : `${url}/${culture}/${currentSkip}/${currentDocumentNodeGuid}/${searchText}/${year}/${categoryNodeAlias}/${careHomeNodeGuid}`;

    return constructedUrl;
  }

  loadmore(url, populateTemplate, currentNewsPanel) {
    $.ajax({
      url: url
    }).done((data) => {
      this.currentLoadCount = this.currentLoadCount + 1;
      populateTemplate( data, currentNewsPanel);
    });
  }

  constructLoadmore(hasmore) {
    if (!hasmore) {
      $(this.sel.componentLoadmore).hide();
    }
  }
}

export default new SearchUtils();
