import Tooltips from './Tooltips';
import { CookieConsent } from '../helpers';

class Shortlist {
  constructor() {
    this.sel = {
      trigger: '[data-shortlist-trigger]',
      close: '[data-shortlist-close]',
      remove: '[data-shortlist-remove]',
      toggle: '[data-shortlist-toggle]',
      dismiss: '[data-shortlist-dismiss]',
      navigate: '[data-shortlist-navigate]',
      county: '[data-shortlist-county]',
      phone: '[data-shortlist-phone]',
      itemsList: '.shortlistoverlay__items .col-12',
      items: '.shortlistoverlay__items',
      item: '.shortlistoverlay__item',
      noItems: '.shortlistoverlay__noItems'
    };
    this.$html = $('html');
    this.shortListCount = 0;

    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.handleConsentChange = this.handleConsentChange.bind(this);
    this.toggleShortlist = this.toggleShortlist.bind(this);
    this.closeShortlist = this.closeShortlist.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.populateItems = this.populateItems.bind(this);
    this.showNoItems = this.showNoItems.bind(this);
    this.showItems = this.showItems.bind(this);
    this.shortlistItem = this.shortlistItem.bind(this);
    this.setCookie = this.setCookie.bind(this);
    this.updatePageElements = this.updatePageElements.bind(this);
    this.optInScreen = this.optInScreen.bind(this);
  }

  bindEvents() {
    $(document).on('click', (e) => {
      const $target = $(e.target);
      if (this.$html.hasClass('shortlistOpen') &&
          $target.parents('.shortlistoverlay').length <= 0 && !$target.is('.shortlistoverlay') &&
          $target.parents(this.sel.trigger).length <= 0 && !$target.is(this.sel.trigger)) {
        this.closeShortlist();
      }
    });
    $(document).on('click', this.sel.trigger, (e) => {
      e.preventDefault();
      this.toggleShortlist();
      this.shortListCount = $(this.sel.trigger).data('count');
      this.pushToDataLayer('View shortlist panel', 'Shortlist Viewed');
    });
    $(document).on('click', this.sel.close, (e) => {
      e.preventDefault();
      this.closeShortlist();
    });
    $(document).on('click', this.sel.remove, (e) => {
      e.preventDefault();
      this.removeItem($(e.currentTarget).attr('data-shortlist-remove'), $(e.currentTarget).attr('data-shortlist-name'));
    });
    $(document).on('click', this.sel.toggle, (e) => {
      e.preventDefault();
      this.toggleItem($(e.currentTarget));
    });
    $(document).on('click', this.sel.dismiss, (e) => {
      e.preventDefault();
      this.closeShortlist();
    });
    $(document).on('click', this.sel.navigate, (e) => {
      this.shortListCount = $(this.sel.trigger).data('count');
      this.getShortlistedGuids().every(guid => {
        if (guid === $(e.target).attr('data-shortlist-navigate')) {
          this.pushToDataLayer('Click to shortlisted care home', $(e.target).attr('data-shortlist-name'));
          return false;
        }
        return true;
      });
    });
    $(document).on('click', this.sel.phone, (e) => {
      this.shortListCount = $(this.sel.trigger).data('count');
      this.getShortlistedGuids().every(guid => {
        if (guid === $(e.target).parent().attr('data-shortlist-phone')) {
          this.pushToDataLayer('Click on phone number', $(e.target).parent().attr('data-shortlist-name'));
          return false;
        }
        return true;
      });

      this.getShortlistedGuids().every(guid => {
        if (guid === $(e.target).attr('data-shortlist-phone')) {
          this.pushToDataLayer('Click on phone number', $(e.target).attr('data-shortlist-name'));
          return false;
        }
        return true;
      });
    });
  }

  handleConsentChange() {
    CookieConsent.onConsentChanged();
    const handleConsentChange = () => {
      if (!CookieConsent.getCookieOptIn()) return;
      this.optInScreen();
      this.populateItems();
      $(window).off('change.cookieconsent.careuk', handleConsentChange);
    };
    $(window).on('change.cookieconsent.careuk', handleConsentChange);
  }

  optInScreen() {
    const optIn = CookieConsent.getCookieOptIn();
    if (!optIn) {
      $(this.sel.noItems).removeClass('d-block');
      $(this.sel.items).removeClass('d-block');
      CookieConsent.openOptInScreen();
      this.handleConsentChange();
    } else {
      this.$html.toggleClass('shortlistOpen');
    }
  }

  toggleShortlist() {
    this.optInScreen();
  }

  closeShortlist() {
    this.$html.removeClass('shortlistOpen');
  }

  getShortlistedGuids() {
    const value = `; ${document.cookie}`;
    const parts = value.split('; careuk_shortlisted=');
    if (parts.length !== 2) return [];
    const currentGuids = parts.pop().split(';').shift();
    if (typeof currentGuids === 'undefined') return [];
    return JSON.parse(currentGuids);
  }

  removeItem(guid, name) {
    const currentGuids = this.getShortlistedGuids();
    const position = currentGuids.indexOf(guid);
    this.shortListCount = currentGuids.length;
    if (position < 0) return;
    currentGuids.splice(position, 1);
    this.setCookie(JSON.stringify(currentGuids));
    this.populateItems().then(() => {
      this.pushToDataLayer('Home unshortlisted', name);
    });
  }

  shortlistItem(guid) {
    const currentGuids = this.getShortlistedGuids();
    currentGuids.push(guid);
    this.setCookie(JSON.stringify(currentGuids));
    return this.populateItems();
  }

  toggleItem(currentTarget) {
    this.pushToDataLayer('Shortlist icon click');
    const currentGuids = this.getShortlistedGuids();
    this.shortListCount = currentGuids.length;
    if (currentGuids.indexOf(currentTarget.attr('data-shortlist-toggle')) < 0) {
      this.shortlistItem(currentTarget.attr('data-shortlist-toggle')).then(() => {
        currentTarget.tooltip('hide').attr('data-bs-original-title', currentTarget.data('tooltip-remove')).tooltip('show');
        if (currentTarget.hasClass('shortlistCta')) {
          this.pushToDataLayer('Save to shortlist - within Care Home', currentTarget.attr('data-shortlist-name'), currentTarget.attr('data-shortlist-county'));
        } else {
          this.pushToDataLayer('Save to shortlist - via care home search', currentTarget.attr('data-shortlist-name'), currentTarget.attr('data-shortlist-county'));
        }
      });
    } else {
      currentTarget.tooltip('hide').attr('data-bs-original-title', currentTarget.data('tooltip-add')).tooltip('show');
      this.removeItem(currentTarget.attr('data-shortlist-toggle'), currentTarget.attr('data-shortlist-name'));
    }
  }

  setCookie(value) {
    if (!CookieConsent.getCookieOptIn()) {
      setTimeout(this.toggleShortlist, 250);
      return;
    }
    var expires = '';
    var date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
    document.cookie = 'careuk_shortlisted=' + (value || '')  + expires + '; path=/';
  }

  updatePageElements() {
    const currentGuids = this.getShortlistedGuids();
    $('[data-shortlist-added]').removeAttr('data-shortlist-added');
    currentGuids.forEach(guid => $(`[data-shortlist-toggle="${guid}"]`).attr('data-shortlist-added', ''));
  }

  getItems() {
    var shortlistedGuids = this.getShortlistedGuids().join('+');
    return fetch(`${window.shortlistEndpoint}?guids=${encodeURIComponent(shortlistedGuids)}`).then((data) => data.json()).catch(() => []);
  }

  showNoItems() {
    if (!CookieConsent.getCookieOptIn()) return;
    $(this.sel.noItems).removeClass('d-none').addClass('d-block');
    $(this.sel.items).removeClass('d-block').addClass('d-none');
  }

  showItems() {
    if (!CookieConsent.getCookieOptIn()) return;
    $(this.sel.noItems).removeClass('d-block').addClass('d-none');
    $(this.sel.items).removeClass('d-none').addClass('d-block');
  }

  async populateItems() {
    if (!CookieConsent.getCookieOptIn()) return;
    this.updatePageElements();
    var items = await this.getItems();
    if (items.length <= 0) {
      this.shortListCount = 0;
      $(this.sel.trigger).removeAttr('data-count');
      this.showNoItems();
      return;
    }

    this.shortListCount = items.length;
    $(this.sel.trigger).attr('data-count', items.length);
    var templateString = $('#shortlistoverlay__itemTemplate').html();
    $(this.sel.itemsList).html('');
    items.forEach((item) => {
      var thisItemString = templateString + '';
      thisItemString = thisItemString.replace(/\$\{GUID\}/g, item.guid);
      thisItemString = thisItemString.replace(/\$\{URL\}/g, item.url);
      thisItemString = thisItemString.replace(/\$\{ADDRESS\}/g, item.address);
      thisItemString = thisItemString.replace(/\$\{NAME\}/g, item.name);
      thisItemString = thisItemString.replace(/\$\{CQC\}/g, item.cqc);
      thisItemString = thisItemString.replace(/\$\{PHONE\}/g, item.phone);
      thisItemString = thisItemString.replace(/\$\{IMG_SRC\}/g, item.img.src);
      thisItemString = thisItemString.replace(/\$\{IMG_ALT\}/g, item.img.alt);
      thisItemString = thisItemString.replace(/\$\{RTAP_NUMBER\}/g, item.responseTapTrackingCode);
      thisItemString = thisItemString.replace(/\$\{TOOLTIP_SHORTLIST_REMOVE\}/g, item.tooltipShortlistRemove);
      $(this.sel.itemsList).append(thisItemString);
    });
    this.showItems();
    if (typeof rTapNotifyDOMChange === 'function') rTapNotifyDOMChange(document.body);
    Tooltips.init();
  }

  pushToDataLayer(action, label = '', county = undefined) {
    window.dataLayer = window.dataLayer || {};
    window.dataLayer.push({
      'event': action,
      'shortListCategory': 'Care home shortlist',
      'shortListLabel': label,
      'shortListValue': this.shortListCount,
      'shortListCounty': county
    });
  }

  init() {
    this.bindEvents();
    this.populateItems();
  }
}

export default new Shortlist();
